import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useBillingStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import DateFormat from '../../../utils/DateFormat';
import {
  LicenceResponse,
  SubscriptionResponseStateEnum,
} from '../../../serverApi/snapguest';

const LicenceList: React.FC = () => {
  const { t } = useTranslation();
  const billingStore = useBillingStore();

  return (
    <Grid container>
      <Grid size={12}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('licence.facility')}</TableCell>
                    <TableCell align="right">{t('licence.validTo')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingStore.facilities?.map((facility) => {
                    const facilityLicences = billingStore.licences?.filter(
                      (l) => l.facilityId === facility?.id,
                    );

                    let maxLicence: LicenceResponse | null = null;
                    if (facilityLicences) {
                      maxLicence = facilityLicences[0];
                      facilityLicences.forEach((l) => {
                        if (
                          maxLicence !== null &&
                          l.validTo > maxLicence.validTo
                        ) {
                          maxLicence = l;
                        }
                      });
                    }

                    if (maxLicence) {
                      return (
                        <TableRow key={facility.id}>
                          <TableCell>
                            <Typography variant="body1">
                              {facility?.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">
                              <DateFormat date={maxLicence.validTo} />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {billingStore.licencePaymentInfos &&
            billingStore.licencePaymentInfos?.length === 0 &&
            (billingStore.subscription?.state ===
              SubscriptionResponseStateEnum.Trial ||
              billingStore.subscription?.state ===
              SubscriptionResponseStateEnum.Active) && (
              <Grid size={12}>
                <Alert severity="success">
                  {t('home.activeSubscription')}{' '}
                  {billingStore.subscription.endsAt}
                </Alert>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(LicenceList);
