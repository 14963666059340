import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import KeyIcon from '@mui/icons-material/Key';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import DateFormat from '../../../utils/DateFormat';
import ChangePasswordForm from '../form/ChangePasswordForm';
import BooleanFormat from '../../../utils/BooleanFormat';

const UserList: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const adminStore = useAdminStore();

  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [showForm, setShowForm] = useState(false);

  const handleUndeleteUser = (userId?: number) => {
    if (userId) {
      adminStore.undeleteUser(userId);
    }
  };

  const handleChangeUserPassword = (userId?: number) => {
    if (userId) {
      setSelectedUserId(userId);
      setShowForm(true);
    }
  };

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('user.email')}</TableCell>
                <TableCell>{t('user.role')}</TableCell>
                <TableCell>{t('user.lastLoginDevice')}</TableCell>
                <TableCell>{t('user.lastLoginAt')}</TableCell>
                <TableCell>{t('user.createdAt')}</TableCell>
                <TableCell>{t('user.deleted')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.customer?.users?.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.lastLoginDevice}</TableCell>
                  <TableCell>
                    <DateFormat date={user.lastLoginAt} showTime />
                  </TableCell>
                  <TableCell>
                    <DateFormat date={user.createdAt} showTime />
                  </TableCell>
                  <TableCell>
                    <BooleanFormat
                      value={user.deleted}
                      color={theme.palette.error.dark}
                    />
                  </TableCell>
                  <TableCell>
                    {user.deleted && (
                      <IconButton onClick={() => handleUndeleteUser(user.id)}>
                        <RestoreFromTrashIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => handleChangeUserPassword(user.id)}
                    >
                      <KeyIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ChangePasswordForm
          userId={selectedUserId}
          show={showForm}
          close={() => {
            setShowForm(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default observer(UserList);
