import React from 'react';

import CheckIcon from '@mui/icons-material/Check';

type Props = {
  value?: boolean;
  color?: string;
};

const BooleanFormat: React.FC<Props> = ({ value, color }) => {
  if (value == undefined) {
    return null;
  }

  if (value) {
    return <CheckIcon sx={color ? { color: color } : undefined} />;
  }

  return;
};

export default BooleanFormat;
