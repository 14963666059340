import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  useMainStore,
  useBillingStore,
} from '../../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { CustomerRequest } from '../../../../serverApi/snapguest';

const CustomerForm: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const billingStore = useBillingStore();

  const validationSchema = yup.object({
    email: yup.string().email(t('validation.email')),
    phone: yup.string().required(t('validation.phone')),
    taxSubject: yup.boolean(),
    companyName: yup.string().when('taxSubject', {
      is: true,
      then: (schema) => schema.required(t('validation.required')),
    }),
    companyIdentificationNumber: yup.string().when('taxSubject', {
      is: true,
      then: (schema) => schema.required(t('validation.required')),
    }),
    ownerFirstName: yup.string().required(t('validation.required')),
    ownerSurname: yup.string().required(t('validation.required')),
    ownerIdentificationNumber: yup.string(),
    address: yup.string().required(t('validation.required')),
    city: yup.string().required(t('validation.required')),
    zipCode: yup.string().required(t('validation.required')),
    countryId: yup.number().required(t('validation.required')),
  });

  const initialValues: CustomerRequest = {
    email: mainStore.customer?.email || '',
    phone: mainStore.customer?.phone || '',
    taxSubject: mainStore.customer?.taxSubject || false,
    companyName: mainStore.customer?.companyName || '',
    companyIdentificationNumber:
      mainStore.customer?.companyIdentificationNumber || '',
    ownerFirstName: mainStore.customer?.ownerFirstName || '',
    ownerSurname: mainStore.customer?.ownerSurname || '',
    ownerIdentificationNumber:
      mainStore.customer?.ownerIdentificationNumber || '',
    address: mainStore.customer?.address || '',
    city: mainStore.customer?.city || '',
    zipCode: mainStore.customer?.zipCode || '',
    countryId:
      mainStore.customer?.countryId || (mainStore.isCroatia ? 191 : 705),
  };

  const formik = useFormik<CustomerRequest>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      billingStore.validateTaxNumber(values, (valid) => {
        if (valid) {
          mainStore.updateCustomer(values);
          billingStore.getSubscriptionInfo();
          billingStore.nextStep();
        }
      });
    },
  });

  let country;
  if (formik.values.countryId) {
    country = billingStore.countries?.find(
      (c) => c.id === formik.values.countryId,
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid size={12}>
          <Grid container spacing={4}>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                label={t('customer.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="phone"
                name="phone"
                label={t('customer.phone')}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder="+385430001111"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerFirstName"
                name="ownerFirstName"
                label={t('customer.ownerFirstName')}
                value={formik.values.ownerFirstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.ownerFirstName &&
                  Boolean(formik.errors.ownerFirstName)
                }
                helperText={
                  formik.touched.ownerFirstName && formik.errors.ownerFirstName
                }
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerSurname"
                name="ownerSurname"
                label={t('customer.ownerSurname')}
                value={formik.values.ownerSurname}
                onChange={formik.handleChange}
                error={
                  formik.touched.ownerSurname &&
                  Boolean(formik.errors.ownerSurname)
                }
                helperText={
                  formik.touched.ownerSurname && formik.errors.ownerSurname
                }
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="h6">{t('home.companyInfo')}</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="companyName"
                name="companyName"
                label={t('customer.companyName')}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                InputLabelProps={{
                  shrink: true,
                  required: formik.values.taxSubject,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="companyIdentificationNumber"
                name="companyIdentificationNumber"
                label={t('customer.companyIdentificationNumber')}
                value={formik.values.companyIdentificationNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.companyIdentificationNumber &&
                  Boolean(formik.errors.companyIdentificationNumber)
                }
                helperText={
                  formik.touched.companyIdentificationNumber &&
                  formik.errors.companyIdentificationNumber
                }
                InputLabelProps={{
                  shrink: true,
                  required: formik.values.taxSubject,
                }}
              />
            </Grid>
            <Grid size={12}>
              <FormControlLabel
                control={
                  <Switch
                    id="taxSubject"
                    name="taxSubject"
                    value={formik.values.taxSubject}
                    checked={formik.values.taxSubject}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label={t('customer.taxSubject')}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="h6">{t('home.addressInfo')}</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label={t('customer.address')}
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="city"
                name="city"
                label={t('customer.city')}
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="zipCode"
                name="zipCode"
                label={t('customer.zipCode')}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />
            </Grid>
            {billingStore.countries && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="countryId"
                  disableClearable
                  value={country}
                  onChange={(e, value) =>
                    formik.setFieldValue('countryId', value?.id || undefined)
                  }
                  options={billingStore.countries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('customer.countryId')}
                      variant="outlined"
                      error={
                        formik.touched.countryId &&
                        Boolean(formik.errors.countryId)
                      }
                      helperText={
                        formik.touched.countryId && formik.errors.countryId
                      }
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!formik.isValid && (
          <Grid size={12}>
            <Alert severity="warning">{t('home.customerWarning')}</Alert>
          </Grid>
        )}
        {!billingStore.taxNumberValid && (
          <Grid size={12}>
            <Alert severity="warning">{t('home.taxNumberWarning')}</Alert>
          </Grid>
        )}
        <Grid size={12}>
          <Grid container justifyContent="flex-end" spacing={4}>
            <Grid size="auto">
              <Button variant="contained" color="primary" type="submit">
                {t('general.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(CustomerForm);
