import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid2';
import { Button, Container, Typography } from '@mui/material';
import { useCheckinStore } from '../../providers/StoresProvider';
import DateFormat from '../../utils/DateFormat';

const Finish: React.FC = () => {
  const { t } = useTranslation();

  const checkinStore = useCheckinStore();

  const handleGoBack = () => {
    history.back();
  };

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="center" spacing={2}>
        <Grid size={12} pt={4}>
          <Typography variant="h4">{t('checkin.thankYou')}</Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="body1">
            {t('checkin.successfullyFilled')}
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="body1">
            <b>{checkinStore.reservationPart?.roomName}</b>
          </Typography>
          <Typography variant="body1">
            {t('checkin.from')}:{' '}
            <DateFormat date={checkinStore.reservationPart?.checkIn} />
          </Typography>
          <Typography variant="body1">
            {t('checkin.to')}:{' '}
            <DateFormat date={checkinStore.reservationPart?.checkOut} />
          </Typography>
          <Typography variant="body1">
            {t('checkin.guests')}:{' '}
            {checkinStore.reservationPart?.checkedInTourists}/
            {checkinStore.reservationPart?.numberOfTourists}
          </Typography>
        </Grid>

        {checkinStore.reservationPart!.checkedInTourists <
          checkinStore.reservationPart!.numberOfTourists && (
            <Grid size={12}>
              <Button variant="contained" color="primary" onClick={handleGoBack}>
                {t('checkin.checkAnother')}
              </Button>
            </Grid>
          )}
      </Grid>
    </Container>
  );
};

export default observer(Finish);
