import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Box from '@mui/material/Box';
import RequireAuth from './utils/RequireAuth';

import { useMainStore } from './providers/StoresProvider';

import Header from './components/general/Header';
import Footer from './components/general/Footer';
import Loader from './components/general/Loader';

import Install from './components/app/Install';
import MailConfirm from './components/app/MailConfirm';
import RegistrationForm from './components/app/form/RegistrationForm';
import ResetPasswordForm from './components/app/form/ResetPasswordForm';
import ChangePasswordForm from './components/app/form/ChangePasswordForm';
import LoginForm from './components/app/form/LoginForm';

import User from './pages/User';
import BillingHome from './pages/billing/Home';
import BillingFinish from './pages/billing/Finish';
import BillingError from './pages/billing/Error';
import AdminHome from './pages/admin/Home';
import CheckinHome from './pages/checkin/Home';
import CheckinFinish from './pages/checkin/Finish';
import ScrollToTop from './utils/ScrollToTop';
import { SubDomain } from './enums/SubDomain';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

function Content() {
  const mainStore = useMainStore();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Router>
          <AppRoutes />
          {mainStore.subDomain === SubDomain.Billing && <BillingRoutes />}
          {mainStore.subDomain === SubDomain.Admin && <AdminRoutes />}
          {mainStore.subDomain === SubDomain.Checkin && <CheckinRoutes />}
        </Router>
      </Box>
      <Footer />
      <Loader />
    </Box>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/app" element={<User />}>
        <Route path="users/password_reset" element={<ResetPasswordForm />} />
        <Route path="users/password_change" element={<ChangePasswordForm />} />
        <Route path="users/mail_validate" element={<MailConfirm />} />
        <Route path="users/register" element={<RegistrationForm />} />
        <Route path="users/login" element={<LoginForm />} />
        <Route path="*" element={<Install />} />
        <Route path="" element={<Install />} />
      </Route>
    </Routes>
  );
}

function BillingRoutes() {
  return (
    <Elements stripe={stripePromise}>
      <ScrollToTop />
      <Routes>
        <Route
          path="/finish"
          element={
            <RequireAuth>
              <BillingFinish />
            </RequireAuth>
          }
        />
        <Route
          path="/error"
          element={
            <RequireAuth>
              <BillingError />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <BillingHome />
            </RequireAuth>
          }
        />
      </Routes>
    </Elements>
  );
}

function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth needsAdminRole>
            <AdminHome />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function CheckinRoutes() {
  return (
    <Routes>
      <Route path="/finish" element={<CheckinFinish />} />
      <Route path="/" element={<CheckinHome />} />
    </Routes>
  );
}

export default Content;
