import { makeObservable, observable, action, runInAction } from 'mobx';
import MainStore from './MainStore';
import { SortDirection } from '@mui/material';
import {
  AdministrationApi,
  ETurizemApi,
  EVisitorApi,
  LicenceRequest,
  LicenceResponse,
  PasswordChangeRequest,
  StatusResponse,
} from '../serverApi/snapguest';
import { Customer } from '../datastructures/customer';
import { ExportType } from '../datastructures/exportType';

class AdminStore {
  mainStore: MainStore;

  // Observable
  loader = false;

  customers?: Customer[];
  filteredCustomers?: Customer[];

  customer?: Customer;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;

    makeObservable(this, {
      loader: observable,
      customers: observable,
      filteredCustomers: observable,
      customer: observable,

      getCustomers: action,
      searchCustomers: action,
      sortCustomers: action,
      getCustomer: action,
      clearCustomer: action,
      createLicence: action,
      undeleteUser: action,
      changeUserPassword: action,
      export: action,
      showLoader: action,
      hideLoader: action,
    });
  }

  // Actions

  getCustomers() {
    this.showLoader();

    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);
    administrationApi
      .listCustomers()
      .then((response) => {
        this.customers = response.data;
        this.filteredCustomers = response.data;

        this.sortCustomers('createdAt', 'desc');
      })
      .finally(() => this.hideLoader());
  }

  searchCustomers(value: string) {
    if (this.customers) {
      if (value === '') {
        this.filteredCustomers = [...this.customers];
      }

      const valueMatch = (field?: string) => {
        return (
          field && field.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
      };

      this.filteredCustomers = this.customers?.filter(
        (c) =>
          valueMatch(c.ownerFirstName) ||
          valueMatch(c.ownerSurname) ||
          valueMatch(c.companyName) ||
          valueMatch(c.companyIdentificationNumber) ||
          valueMatch(c.address) ||
          valueMatch(c.zipCode) ||
          valueMatch(c.city) ||
          valueMatch(c.email),
      );
    }
  }

  sortCustomers(field: string, direction: SortDirection) {
    this.filteredCustomers?.sort((a, b) => {
      type CustomerKey = keyof typeof a;

      const aField = a[field as CustomerKey] || '';
      const bField = b[field as CustomerKey] || '';

      return (
        (direction === 'desc' ? -1 : 1) *
        (typeof aField === 'string' && typeof bField === 'string'
          ? aField.localeCompare(bField)
          : 0)
      );
    });
  }

  getCustomer(customerId: number) {
    this.showLoader();

    this.customer = this.customers?.find((c) => c.id === customerId);

    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);

    const eVisitorApi = new EVisitorApi(this.mainStore.apiConfig);
    const eTurizemApi = new ETurizemApi(this.mainStore.apiConfig);

    let getMiddlewareAccounts;

    if (this.mainStore.isCroatia) {
      getMiddlewareAccounts = eVisitorApi
        .listCustomerEvAccounts(customerId)
        .then((response) => {
          runInAction(() => {
            if (this.customer && response.data) {
              this.customer.evAccounts = response.data;
            }
          });
        });
    }

    if (this.mainStore.isSlovenia) {
      getMiddlewareAccounts = eTurizemApi
        .listCustomerEtAccounts(customerId)
        .then((response) => {
          runInAction(() => {
            if (this.customer && response.data) {
              this.customer.etAccounts = response.data;
            }
          });
        });
    }

    Promise.all([
      getMiddlewareAccounts,
      administrationApi.listCustomerUsers(customerId).then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            this.customer.users = response.data;
          }
        });
      }),
      administrationApi.listCustomerFacilities(customerId).then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            this.customer.facilities = response.data;
          }
        });
      }),
      administrationApi.listCustomerLicences(customerId).then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            this.customer.licences = response.data.sort((a, b) =>
              b.validTo.localeCompare(a.validTo),
            );
          }
        });
      }),
    ]).then(() => {
      this.hideLoader();
    });
  }

  clearCustomer() {
    this.customer = undefined;
  }

  deleteEvisitor(customerId: number, id: number) {
    this.showLoader();

    const eVisitorApi = new EVisitorApi(this.mainStore.apiConfig);
    eVisitorApi
      .deleteCustomerEvAccounts(customerId, id)
      .then((response) => {
        runInAction(() => {
          if (this.customer && response.data?.success) {
            this.customer.evAccounts = this.customer?.evAccounts?.filter(
              (a) => a.id !== id,
            );
          }
        });
      })
      .finally(() => this.hideLoader());
  }

  deleteEturizemAccount(customerId: number, id: number) {
    this.showLoader();

    const eTurizemApi = new ETurizemApi(this.mainStore.apiConfig);
    eTurizemApi
      .deleteCustomerEtAccounts(customerId, id)
      .then((response) => {
        runInAction(() => {
          if (this.customer && response.data?.success) {
            this.customer.etAccounts = this.customer?.etAccounts?.filter(
              (a) => a.id !== id,
            );
          }
        });
      })
      .finally(() => this.hideLoader());
  }

  createLicence(
    customerId: number,
    postData: LicenceRequest,
    callback: (data: LicenceResponse | null) => void,
  ) {
    this.showLoader();

    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);
    administrationApi
      .createCustomerLicences(customerId, postData)
      .then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            if (this.customer.licences === undefined) {
              this.customer.licences = [];
            }

            this.customer.licences.unshift(response.data);

            callback(response.data);
          } else {
            callback(null);
          }
        });
      })
      .catch(() => callback(null))
      .finally(() => this.hideLoader());
  }

  undeleteUser(userId: number) {
    this.showLoader();

    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);
    administrationApi
      .undeleteUser(userId)
      .then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            const user = this.customer.users?.find((u) => u.id === userId);

            if (user) {
              user.deleted = false;
            }
          }
        });
      })
      .finally(() => this.hideLoader());
  }

  changeUserPassword(
    userId: number,
    postData: PasswordChangeRequest,
    callback: (data: StatusResponse | null) => void,
  ) {
    this.showLoader();

    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);
    administrationApi
      .userPasswordChange(userId, postData)
      .then((response) => {
        runInAction(() => {
          if (this.customer && response.data) {
            callback(response.data);
          } else {
            callback(null);
          }
          this.hideLoader();
        });
      })
      .catch(() => callback(null))
      .finally(() => this.hideLoader());
  }

  export(type: ExportType) {
    const administrationApi = new AdministrationApi(this.mainStore.apiConfig);
    administrationApi._export(type).then((response) => {
      if (this.customer && response.data) {
        const fileName = `${type}.csv`;

        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create link HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // clean up link element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    });
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }
}

export default AdminStore;
