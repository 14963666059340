import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {
  IconButton,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { Clear, Visibility } from '@mui/icons-material';
import DateFormat from '../../../utils/DateFormat';
import BooleanFormat from '../../../utils/BooleanFormat';

const CustomerList: React.FC = () => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  const [searchValue, setSearchValue] = useState('');

  const [orderBy, setOrderBy] = useState('createdAt');
  const [orderDirection, setOrderDirection] = useState<SortDirection>('desc');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    adminStore.searchCustomers(event.target.value);

    adminStore.sortCustomers(orderBy, orderDirection);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    adminStore.searchCustomers('');

    adminStore.sortCustomers(orderBy, orderDirection);
  };

  const handleViewCustomer = (id?: number) => {
    if (id) {
      adminStore.getCustomer(id);
    }
  };

  const handleOrder = (field: string) => {
    setOrderBy(field);

    const direction: SortDirection = orderDirection === 'asc' ? 'desc' : 'asc';

    setOrderDirection(direction);

    adminStore.sortCustomers(field, direction);
  };

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <Typography variant="h5">{t('admin.customers')}</Typography>
      </Grid>
      <Grid size={12}>
        <TextField
          autoFocus
          label={t('admin.search')}
          variant="outlined"
          value={searchValue}
          onChange={handleSearch}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={handleClearSearch}>
                  <Clear />
                </IconButton>
              ),
            },
          }}
        />
      </Grid>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={orderBy === 'name' ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === 'ownerFirstName'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('ownerFirstName')}
                  >
                    {t('customer.ownerFirstName')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={
                    orderBy === 'ownerSurname' ? orderDirection : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === 'ownerSurname'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('ownerSurname')}
                  >
                    {t('customer.ownerSurname')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === 'email' ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('email')}
                  >
                    {t('customer.email')}
                  </TableSortLabel>
                </TableCell>
                <TableCell> {t('customer.phone')}</TableCell>
                <TableCell
                  sortDirection={orderBy === 'address' ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === 'address'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('address')}
                  >
                    {t('customer.address')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === 'zipCode' ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === 'zipCode'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('zipCode')}
                  >
                    {t('customer.zipCode')}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={orderBy === 'city' ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === 'city'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('city')}
                  >
                    {t('customer.city')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t('customer.countryId')}</TableCell>
                <TableCell>{t('customer.taxSubject')}</TableCell>
                <TableCell
                  sortDirection={
                    orderBy === 'companyName' ? orderDirection : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === 'companyName'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('companyName')}
                  >
                    {t('customer.companyName')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  {t('customer.companyIdentificationNumber')}
                </TableCell>
                <TableCell
                  sortDirection={
                    orderBy === 'createAt' ? orderDirection : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderDirection || undefined}
                    onClick={() => handleOrder('createdAt')}
                  >
                    {t('customer.createdAt')}
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.filteredCustomers?.map((customer) => (
                <TableRow
                  key={customer.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{customer.ownerFirstName}</TableCell>
                  <TableCell>{customer.ownerSurname}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{customer.address}</TableCell>
                  <TableCell>{customer.zipCode}</TableCell>
                  <TableCell>{customer.city}</TableCell>
                  <TableCell>{customer.countryId}</TableCell>
                  <TableCell>
                    <BooleanFormat value={customer.taxSubject} />
                  </TableCell>
                  <TableCell>{customer.companyName}</TableCell>
                  <TableCell>{customer.companyIdentificationNumber}</TableCell>
                  <TableCell>
                    <DateFormat date={customer.createdAt} showTime />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewCustomer(customer.id)}>
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default observer(CustomerList);
