import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid2';
import { Container, Typography } from '@mui/material';
import CheckinForm from '../../components/checkin/form/CheckinForm';
import { useCheckinStore } from '../../providers/StoresProvider';
import DateFormat from '../../utils/DateFormat';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const checkinStore = useCheckinStore();

  const freeCheckInSpaces =
    checkinStore.reservationPart &&
    checkinStore.reservationPart.numberOfTourists >
    checkinStore.reservationPart.checkedInTourists;

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="center" spacing={2}>
        <Grid size={12} mt={4}>
          <Typography variant="h4">{t('checkin.selfcheckin')}</Typography>
        </Grid>
        {checkinStore.validLink ? (
          freeCheckInSpaces ? (
            <>
              <Grid size={12}>
                <Typography variant="body1">
                  {t('checkin.welcome')},{' '}
                  <b>
                    {checkinStore.reservationPart?.holderFirstName}{' '}
                    {checkinStore.reservationPart?.holderLastName}
                  </b>{' '}
                  {t('checkin.in')} {checkinStore.reservationPart?.facilityName}
                </Typography>
              </Grid>
              {checkinStore.note && (
                <Grid size={12}>
                  <Typography variant="body2">{checkinStore.note}</Typography>
                </Grid>
              )}
              <Grid size={12}>
                <Typography variant="body1">
                  {t('checkin.yourReservation')}
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography variant="body1">
                  <b>{checkinStore.reservationPart?.roomName}</b>
                </Typography>
                <Typography variant="body1">
                  {t('checkin.from')}:{' '}
                  <DateFormat date={checkinStore.reservationPart?.checkIn} />
                </Typography>
                <Typography variant="body1">
                  {t('checkin.to')}:{' '}
                  <DateFormat date={checkinStore.reservationPart?.checkOut} />
                </Typography>
                <Typography variant="body1">
                  {t('checkin.guests')}:{' '}
                  {checkinStore.reservationPart?.checkedInTourists}/
                  {checkinStore.reservationPart?.numberOfTourists}
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography variant="body1">
                  {t('checkin.pleaseCheckin')}
                </Typography>
              </Grid>

              <Grid size={12}>
                {checkinStore.reservationPart && <CheckinForm />}
              </Grid>
            </>
          ) : (
            <Grid size={12}>{t('checkin.checkInFull')}</Grid>
          )
        ) : (
          <Grid size={12}>{t('checkin.invalidLink')}</Grid>
        )}
      </Grid>
    </Container>
  );
};

export default observer(Home);
