import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore, useMainStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import BooleanFormat from '../../../utils/BooleanFormat';

const FacilityList: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const adminStore = useAdminStore();

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {mainStore.isSlovenia && (
                  <TableCell>{t('facility.info')}</TableCell>
                )}
                <TableCell>{t('facility.name')}</TableCell>
                <TableCell>{t('facility.type')}</TableCell>
                <TableCell>{t('facility.streetNumber')}</TableCell>
                <TableCell>{t('facility.settlementZone')}</TableCell>
                <TableCell>{t('facility.settlement')}</TableCell>
                <TableCell>{t('facility.checkoutTime')}</TableCell>
                <TableCell>{t('facility.showOnCalendar')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.customer?.facilities?.map((facility) => (
                <TableRow
                  key={facility.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {mainStore.isSlovenia && (
                    <TableCell>{facility.info}</TableCell>
                  )}
                  <TableCell>{facility.name}</TableCell>
                  <TableCell>{facility.type}</TableCell>
                  <TableCell>{facility.streetNumber}</TableCell>
                  <TableCell>{facility.settlementZone}</TableCell>
                  <TableCell>{facility.settlement}</TableCell>
                  <TableCell>{facility.checkoutTime}</TableCell>
                  <TableCell>
                    <BooleanFormat value={facility.showOnCalendar} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default observer(FacilityList);
