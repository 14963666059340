import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  useMainStore,
  useCheckinStore,
} from '../../../providers/StoresProvider';
import { EvCpDocumentTypeResponse } from '../../../serverApi/snapguest';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {
  CheckInPartRequest,
  CheckInPartRequestGenderEnum,
} from '../../../serverApi/snapguest';
import { FormControl, InputLabel, Link, MenuItem, Select } from '@mui/material';
import { formatISO, getDate, getMonth, getYear, set } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const CheckinForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainStore = useMainStore();
  const checkinStore = useCheckinStore();

  const validationSchema = yup.object({
    checkIn: yup.string().required(t('validation.required')),
    checkOut: yup.string().required(t('validation.required')),
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    dateOfBirth: yup.string().required(t('validation.required')),
    documentTypeId: yup.string().required(t('validation.required')),
    documentNumber: yup.string().required(t('validation.required')),
    countryOfResidenceId: yup.string().required(t('validation.required')),
    countryOfBirthId: yup.string().required(t('validation.required')),
    countryOfCitizenshipId: yup.string().required(t('validation.required')),
    cityOfResidence: mainStore.isCroatia
      ? yup.string().required(t('validation.required'))
      : yup.string(),
    arrivalOrganisationId: mainStore.isCroatia
      ? yup.string().required(t('validation.required'))
      : yup.string(),
  });

  const fromDate = formatISO(checkinStore.reservationPart!.checkIn, {
    representation: 'date',
  });

  const toDate = formatISO(checkinStore.reservationPart!.checkOut, {
    representation: 'date',
  });

  const initialValues = {
    checkIn: fromDate,
    checkOut: toDate,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: CheckInPartRequestGenderEnum.Male,
    documentTypeId: mainStore.isCroatia
      ? 'b65574b0-c6ec-4a73-8e36-b407bb315591'
      : 'I',
    documentNumber: '',
    countryOfResidenceId: mainStore.isCroatia ? 191 : 705,
    countryOfBirthId: mainStore.isCroatia ? 191 : 705,
    countryOfCitizenshipId: mainStore.isCroatia ? 191 : 705,
    cityOfResidence: '',
    arrivalOrganisationId: mainStore.isCroatia
      ? '855cb6a3-c761-4cb6-b527-564ecb202b18'
      : '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (checkinStore.customerId && checkinStore.partId) {
        const checkIn = set(checkinStore.reservationPart!.checkIn, {
          year: getYear(values.checkIn),
          month: getMonth(values.checkIn),
          date: getDate(values.checkIn),
        });

        const checkOut = set(checkinStore.reservationPart!.checkOut, {
          year: getYear(values.checkOut),
          month: getMonth(values.checkOut),
          date: getDate(values.checkOut),
        });

        const postData: CheckInPartRequest = {
          reservationPartId: checkinStore.partId,
          checkIn: checkIn.toISOString(),
          checkOut: checkOut.toISOString(),
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: values.dateOfBirth,
          gender: values.gender,
          documentType: values.documentTypeId,
          documentNumber: values.documentNumber,
          countryOfResidenceId: values.countryOfResidenceId,
          countryOfBirthId: values.countryOfBirthId,
          countryOfCitizenshipId: values.countryOfCitizenshipId,
          cityOfResidence: values.cityOfResidence,
          arrivalOrganisation: values.arrivalOrganisationId,
        };

        checkinStore.selfCheckInTourist(
          checkinStore.customerId,
          postData,
          (success) => {
            if (success) {
              navigate('/finish');
            }
          },
        );
      }
    },
  });

  let country;
  if (formik.values.countryOfResidenceId) {
    country = checkinStore.countries.find(
      (c) => c.id === formik.values.countryOfResidenceId,
    );
  }

  let documentType;
  if (formik.values.documentTypeId) {
    documentType = checkinStore.documentTypes.find(
      (c) => c.id === formik.values.documentTypeId,
    );
  }

  let arrivalOrganisation;
  if (formik.values.arrivalOrganisationId) {
    arrivalOrganisation = checkinStore.arrivalOrganizations.find(
      (c) => c.id === formik.values.arrivalOrganisationId,
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid size={12}>
          <Grid container spacing={4}>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                id="checkIn"
                name="checkIn"
                label={t('checkin.checkIn')}
                value={formik.values.checkIn}
                onChange={formik.handleChange}
                error={formik.touched.checkIn && Boolean(formik.errors.checkIn)}
                helperText={formik.touched.checkIn && formik.errors.checkIn}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                  htmlInput: {
                    onFocus: (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.showPicker();
                    },
                    min: fromDate,
                    max: toDate,
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                id="checkOut"
                name="checkOut"
                label={t('checkin.checkOut')}
                value={formik.values.checkOut}
                onChange={formik.handleChange}
                error={
                  formik.touched.checkOut && Boolean(formik.errors.checkOut)
                }
                helperText={formik.touched.checkOut && formik.errors.checkOut}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                  htmlInput: {
                    onFocus: (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.showPicker();
                    },
                    min: fromDate,
                    max: toDate,
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="firstName"
                name="firstName"
                label={t('checkin.firstName')}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="lastName"
                name="lastName"
                label={t('checkin.lastName')}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                label={t('checkin.dateOfBirth')}
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                error={
                  formik.touched.dateOfBirth &&
                  Boolean(formik.errors.dateOfBirth)
                }
                helperText={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                  htmlInput: {
                    onFocus: (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.showPicker();
                    },
                    max: formatISO(new Date(), {
                      representation: 'date',
                    }),
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <InputLabel shrink required>
                  {t('checkin.gender')}
                </InputLabel>
                <Select
                  label={t('auth.server')}
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  variant="outlined"
                >
                  <MenuItem value={CheckInPartRequestGenderEnum.Male}>
                    {t('checkin.male')}
                  </MenuItem>
                  <MenuItem value={CheckInPartRequestGenderEnum.Female}>
                    {t('checkin.female')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {checkinStore.documentTypes && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="documentType"
                  disableClearable
                  value={documentType}
                  onChange={(e, value) =>
                    formik.setFieldValue(
                      'documentTypeId',
                      value?.id || undefined,
                    )
                  }
                  options={checkinStore.documentTypes}
                  getOptionLabel={(option) => {
                    let id = option.id;
                    if (mainStore.isCroatia) {
                      id = (option as EvCpDocumentTypeResponse).codeMi;
                    }
                    return t('documentType.' + id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('checkin.documentType')}
                      variant="outlined"
                      error={
                        formik.touched.documentTypeId &&
                        Boolean(formik.errors.documentTypeId)
                      }
                      helperText={
                        formik.touched.documentTypeId &&
                        formik.errors.documentTypeId
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="documentNumber"
                name="documentNumber"
                label={t('checkin.documentNumber')}
                value={formik.values.documentNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.documentNumber &&
                  Boolean(formik.errors.documentNumber)
                }
                helperText={
                  formik.touched.documentNumber && formik.errors.documentNumber
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    required: true,
                  },
                }}
              />
            </Grid>

            {checkinStore.countries && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="countryOfResidenceId"
                  disableClearable
                  value={country}
                  onChange={(e, value) => {
                    formik.setFieldValue(
                      'countryOfResidenceId',
                      value?.id || undefined,
                    );

                    formik.setFieldValue(
                      'countryOfBirthId',
                      value?.id || undefined,
                    );

                    formik.setFieldValue(
                      'countryOfCitizenshipId',
                      value?.id || undefined,
                    );
                  }}
                  options={checkinStore.countries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('checkin.countryOfResidence')}
                      variant="outlined"
                      error={
                        formik.touched.countryOfResidenceId &&
                        Boolean(formik.errors.countryOfResidenceId)
                      }
                      helperText={
                        formik.touched.countryOfResidenceId &&
                        formik.errors.countryOfResidenceId
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {mainStore.isCroatia &&
              formik.values.countryOfResidenceId !== 191 && (
                <Grid size={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="cityOfResidence"
                    name="cityOfResidence"
                    label={t('checkin.cityOfResidence')}
                    value={formik.values.cityOfResidence}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cityOfResidence &&
                      Boolean(formik.errors.cityOfResidence)
                    }
                    helperText={
                      formik.touched.cityOfResidence &&
                      formik.errors.cityOfResidence
                    }
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                        required: true,
                      },
                    }}
                  />
                </Grid>
              )}

            {mainStore.isCroatia &&
              checkinStore.settlements &&
              formik.values.countryOfResidenceId === 191 && (
                <Grid size={12}>
                  <Autocomplete
                    fullWidth
                    id="cityOfResidence"
                    disableClearable
                    value={formik.values.cityOfResidence}
                    onChange={(e, value) =>
                      formik.setFieldValue(
                        'cityOfResidence',
                        value || undefined,
                      )
                    }
                    options={checkinStore.settlements}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('checkin.cityOfResidence')}
                        variant="outlined"
                        error={
                          formik.touched.cityOfResidence &&
                          Boolean(formik.errors.cityOfResidence)
                        }
                        helperText={
                          formik.touched.cityOfResidence &&
                          formik.errors.cityOfResidence
                        }
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                            required: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

            {mainStore.isCroatia && checkinStore.countries && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="countryOfBirthId"
                  disableClearable
                  value={country}
                  onChange={(e, value) =>
                    formik.setFieldValue(
                      'countryOfBirthId',
                      value?.id || undefined,
                    )
                  }
                  options={checkinStore.countries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('checkin.countryOfBirth')}
                      variant="outlined"
                      error={
                        formik.touched.countryOfBirthId &&
                        Boolean(formik.errors.countryOfBirthId)
                      }
                      helperText={
                        formik.touched.countryOfBirthId &&
                        formik.errors.countryOfBirthId
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {mainStore.isCroatia && checkinStore.countries && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="countryOfCitizenshipId"
                  disableClearable
                  value={country}
                  onChange={(e, value) =>
                    formik.setFieldValue(
                      'countryOfCitizenshipId',
                      value?.id || undefined,
                    )
                  }
                  options={checkinStore.countries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('checkin.countryOfCitizenship')}
                      variant="outlined"
                      error={
                        formik.touched.countryOfCitizenshipId &&
                        Boolean(formik.errors.countryOfCitizenshipId)
                      }
                      helperText={
                        formik.touched.countryOfCitizenshipId &&
                        formik.errors.countryOfCitizenshipId
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {mainStore.isCroatia && checkinStore.arrivalOrganizations && (
              <Grid size={12}>
                <Autocomplete
                  fullWidth
                  id="arrivalOrganisation"
                  disableClearable
                  value={arrivalOrganisation}
                  onChange={(e, value) =>
                    formik.setFieldValue(
                      'arrivalOrganisationId',
                      value?.id || undefined,
                    )
                  }
                  options={checkinStore.arrivalOrganizations}
                  getOptionLabel={(option) =>
                    t('arrivalOrganisation.' + option.codeMi)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('checkin.arrivalOrganisation')}
                      variant="outlined"
                      error={
                        formik.touched.arrivalOrganisationId &&
                        Boolean(formik.errors.arrivalOrganisationId)
                      }
                      helperText={
                        formik.touched.arrivalOrganisationId &&
                        formik.errors.arrivalOrganisationId
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!formik.isValid && (
          <Grid size={12}>
            <Alert severity="warning">{t('checkin.checkinWarning')}</Alert>
          </Grid>
        )}
        <Grid size={12}>
          <Typography variant="body2">
            {t('checkin.agreeWith')}{' '}
            <Link href="" color="textPrimary">
              {t('checkin.terms')}
            </Link>
            {'.'}
          </Typography>
        </Grid>
        <Grid size={12} mb={4}>
          <Button variant="contained" color="primary" type="submit">
            {t('checkin.sendToAccomodation')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(CheckinForm);
