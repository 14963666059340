/* tslint:disable */
/* eslint-disable */
/**
 * Snapguest Backend
 * Backend API Documentation
 *
 * The version of the OpenAPI document: 1.25.0
 * Contact: info@snapguest.si
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Notification\'s additional data
 * @export
 * @interface AdditionalDataResponse
 */
export interface AdditionalDataResponse {
    /**
     * Notification type: evisitor-no-permissions
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'link': string;
    /**
     * Notification type: tourist-birthday
     * @type {number}
     * @memberof AdditionalDataResponse
     */
    'id': number;
    /**
     * Notification type: tourist-birthday
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'firstName': string;
    /**
     * Notification type: tourist-birthday
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'lastName': string;
    /**
     * Notification type: tourist-birthday
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'dateOfBirth': string;
    /**
     * Notification type: licence-will-expire, licence-expired
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'facilityId': string;
    /**
     * Notification type: licence-will-expire, licence-expired
     * @type {string}
     * @memberof AdditionalDataResponse
     */
    'validTo': string;
}
/**
 * 
 * @export
 * @interface CashierActivationRequest
 */
export interface CashierActivationRequest {
    /**
     * Email
     * @type {string}
     * @memberof CashierActivationRequest
     */
    'email': string;
    /**
     * Password
     * @type {string}
     * @memberof CashierActivationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CashierOperatorRequest
 */
export interface CashierOperatorRequest {
    /**
     * Operator\'s name
     * @type {string}
     * @memberof CashierOperatorRequest
     */
    'name': string;
    /**
     * Operator\'s tax number
     * @type {string}
     * @memberof CashierOperatorRequest
     */
    'taxNumber': string;
}
/**
 * 
 * @export
 * @interface CashierOperatorResponse
 */
export interface CashierOperatorResponse {
    /**
     * Operator\'s name
     * @type {string}
     * @memberof CashierOperatorResponse
     */
    'name': string;
    /**
     * Operator\'s tax number
     * @type {string}
     * @memberof CashierOperatorResponse
     */
    'taxNumber'?: string;
}
/**
 * 
 * @export
 * @interface CheckInLinkRequest
 */
export interface CheckInLinkRequest {
    /**
     * Tourist\'s email
     * @type {string}
     * @memberof CheckInLinkRequest
     */
    'email'?: string;
    /**
     * Note from host
     * @type {string}
     * @memberof CheckInLinkRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface CheckInLinkResponse
 */
export interface CheckInLinkResponse {
    /**
     * Self check-in link
     * @type {string}
     * @memberof CheckInLinkResponse
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface CheckInPartRequest
 */
export interface CheckInPartRequest {
    /**
     * Tourist\'s first name
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'firstName': string;
    /**
     * Tourist\'s last name
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'lastName': string;
    /**
     * Tourist\'s gender
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'gender': CheckInPartRequestGenderEnum;
    /**
     * Tourist\'s document number
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'documentNumber': string;
    /**
     * Tourist\'s document type
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'documentType': string;
    /**
     * Tourist\'s date of birth
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'dateOfBirth': string;
    /**
     * Tourist\'s country of birth
     * @type {number}
     * @memberof CheckInPartRequest
     */
    'countryOfBirthId': number;
    /**
     * Tourist\'s city of birth
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'cityOfBirth'?: string;
    /**
     * Tourist\'s residence city
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'cityOfResidence'?: string;
    /**
     * Tourist\'s country of residence
     * @type {number}
     * @memberof CheckInPartRequest
     */
    'countryOfResidenceId': number;
    /**
     * Tourist\'s citizenship
     * @type {number}
     * @memberof CheckInPartRequest
     */
    'countryOfCitizenshipId': number;
    /**
     * Tourist\'s telephone (format: +385916655333)
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'telephone'?: string;
    /**
     * Tourist\'s email
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'email'?: string;
    /**
     * Offered service
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'offeredService'?: string;
    /**
     * Arrival organisation type
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'arrivalOrganisation'?: string;
    /**
     * Facility\'s tax payment category
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'paymentCategory'?: string;
    /**
     * Facility\'s tax payment category description
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'paymentCategoryDescription'?: string;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof CheckInPartRequest
     */
    'checkOut': string;
    /**
     * Reservation part\'s ID
     * @type {number}
     * @memberof CheckInPartRequest
     */
    'reservationPartId': number;
}

export const CheckInPartRequestGenderEnum = {
    Male: 'male',
    Female: 'female'
} as const;

export type CheckInPartRequestGenderEnum = typeof CheckInPartRequestGenderEnum[keyof typeof CheckInPartRequestGenderEnum];

/**
 * 
 * @export
 * @interface CheckInRequest
 */
export interface CheckInRequest {
    /**
     * Tourist\'s first name
     * @type {string}
     * @memberof CheckInRequest
     */
    'firstName': string;
    /**
     * Tourist\'s last name
     * @type {string}
     * @memberof CheckInRequest
     */
    'lastName': string;
    /**
     * Tourist\'s gender
     * @type {string}
     * @memberof CheckInRequest
     */
    'gender': CheckInRequestGenderEnum;
    /**
     * Tourist\'s document number
     * @type {string}
     * @memberof CheckInRequest
     */
    'documentNumber': string;
    /**
     * Tourist\'s document type
     * @type {string}
     * @memberof CheckInRequest
     */
    'documentType': string;
    /**
     * Tourist\'s date of birth
     * @type {string}
     * @memberof CheckInRequest
     */
    'dateOfBirth': string;
    /**
     * Tourist\'s country of birth
     * @type {number}
     * @memberof CheckInRequest
     */
    'countryOfBirthId': number;
    /**
     * Tourist\'s city of birth
     * @type {string}
     * @memberof CheckInRequest
     */
    'cityOfBirth'?: string;
    /**
     * Tourist\'s residence city
     * @type {string}
     * @memberof CheckInRequest
     */
    'cityOfResidence'?: string;
    /**
     * Tourist\'s country of residence
     * @type {number}
     * @memberof CheckInRequest
     */
    'countryOfResidenceId': number;
    /**
     * Tourist\'s citizenship
     * @type {number}
     * @memberof CheckInRequest
     */
    'countryOfCitizenshipId': number;
    /**
     * Tourist\'s telephone (format: +385916655333)
     * @type {string}
     * @memberof CheckInRequest
     */
    'telephone'?: string;
    /**
     * Tourist\'s email
     * @type {string}
     * @memberof CheckInRequest
     */
    'email'?: string;
    /**
     * Offered service
     * @type {string}
     * @memberof CheckInRequest
     */
    'offeredService'?: string;
    /**
     * Arrival organisation type
     * @type {string}
     * @memberof CheckInRequest
     */
    'arrivalOrganisation'?: string;
    /**
     * Facility\'s tax payment category
     * @type {string}
     * @memberof CheckInRequest
     */
    'paymentCategory'?: string;
    /**
     * Facility\'s tax payment category description
     * @type {string}
     * @memberof CheckInRequest
     */
    'paymentCategoryDescription'?: string;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof CheckInRequest
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof CheckInRequest
     */
    'checkOut': string;
}

export const CheckInRequestGenderEnum = {
    Male: 'male',
    Female: 'female'
} as const;

export type CheckInRequestGenderEnum = typeof CheckInRequestGenderEnum[keyof typeof CheckInRequestGenderEnum];

/**
 * 
 * @export
 * @interface CountryResponse
 */
export interface CountryResponse {
    /**
     * Country\'s ID
     * @type {number}
     * @memberof CountryResponse
     */
    'id': number;
    /**
     * Country\'s alpha 2 code
     * @type {string}
     * @memberof CountryResponse
     */
    'alpha2': string;
    /**
     * Country\'s alpha 3 code
     * @type {string}
     * @memberof CountryResponse
     */
    'alpha3': string;
    /**
     * Country\'s name
     * @type {string}
     * @memberof CountryResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CouponInfoResponse
 */
export interface CouponInfoResponse {
    /**
     * Coupon validity
     * @type {boolean}
     * @memberof CouponInfoResponse
     */
    'valid': boolean;
    /**
     * Payment service coupon\'s ID
     * @type {string}
     * @memberof CouponInfoResponse
     */
    'serviceId'?: string;
    /**
     * Coupon\'s amount off
     * @type {number}
     * @memberof CouponInfoResponse
     */
    'amountOff'?: number;
    /**
     * Coupon\'s percent off
     * @type {number}
     * @memberof CouponInfoResponse
     */
    'percentOff'?: number;
}
/**
 * 
 * @export
 * @interface CustomerRequest
 */
export interface CustomerRequest {
    /**
     * Email
     * @type {string}
     * @memberof CustomerRequest
     */
    'email': string;
    /**
     * Phone
     * @type {string}
     * @memberof CustomerRequest
     */
    'phone': string;
    /**
     * Is tax subject
     * @type {boolean}
     * @memberof CustomerRequest
     */
    'taxSubject': boolean;
    /**
     * Company name
     * @type {string}
     * @memberof CustomerRequest
     */
    'companyName'?: string;
    /**
     * Company identification number
     * @type {string}
     * @memberof CustomerRequest
     */
    'companyIdentificationNumber'?: string;
    /**
     * Owner first name
     * @type {string}
     * @memberof CustomerRequest
     */
    'ownerFirstName': string;
    /**
     * Owner surname
     * @type {string}
     * @memberof CustomerRequest
     */
    'ownerSurname': string;
    /**
     * Owner identification number
     * @type {string}
     * @memberof CustomerRequest
     */
    'ownerIdentificationNumber'?: string;
    /**
     * Address
     * @type {string}
     * @memberof CustomerRequest
     */
    'address': string;
    /**
     * City
     * @type {string}
     * @memberof CustomerRequest
     */
    'city': string;
    /**
     * ZIP code
     * @type {string}
     * @memberof CustomerRequest
     */
    'zipCode': string;
    /**
     * Country
     * @type {number}
     * @memberof CustomerRequest
     */
    'countryId': number;
}
/**
 * 
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
    /**
     * Customer\'s ID
     * @type {number}
     * @memberof CustomerResponse
     */
    'id': number;
    /**
     * Owner\'s user ID
     * @type {number}
     * @memberof CustomerResponse
     */
    'ownerId': number;
    /**
     * Email
     * @type {string}
     * @memberof CustomerResponse
     */
    'email'?: string;
    /**
     * Phone
     * @type {string}
     * @memberof CustomerResponse
     */
    'phone'?: string;
    /**
     * Is tax subject
     * @type {boolean}
     * @memberof CustomerResponse
     */
    'taxSubject': boolean;
    /**
     * Company name
     * @type {string}
     * @memberof CustomerResponse
     */
    'companyName'?: string;
    /**
     * Company identification number
     * @type {string}
     * @memberof CustomerResponse
     */
    'companyIdentificationNumber'?: string;
    /**
     * Owner first name
     * @type {string}
     * @memberof CustomerResponse
     */
    'ownerFirstName'?: string;
    /**
     * Owner surname
     * @type {string}
     * @memberof CustomerResponse
     */
    'ownerSurname'?: string;
    /**
     * Owner identification number
     * @type {string}
     * @memberof CustomerResponse
     */
    'ownerIdentificationNumber'?: string;
    /**
     * Address
     * @type {string}
     * @memberof CustomerResponse
     */
    'address'?: string;
    /**
     * City
     * @type {string}
     * @memberof CustomerResponse
     */
    'city'?: string;
    /**
     * ZIP code
     * @type {string}
     * @memberof CustomerResponse
     */
    'zipCode'?: string;
    /**
     * Country
     * @type {number}
     * @memberof CustomerResponse
     */
    'countryId'?: number;
    /**
     * Customer\'s created date and time
     * @type {string}
     * @memberof CustomerResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface CustomerSettingResponse
 */
export interface CustomerSettingResponse {
    /**
     * Setting\'s ID
     * @type {number}
     * @memberof CustomerSettingResponse
     */
    'id': number;
    /**
     * Setting\'s name
     * @type {string}
     * @memberof CustomerSettingResponse
     */
    'name': CustomerSettingResponseNameEnum;
    /**
     * Setting\'s value
     * @type {string}
     * @memberof CustomerSettingResponse
     */
    'value': string;
}

export const CustomerSettingResponseNameEnum = {
    EasyMode: 'easy_mode',
    CashierEnabled: 'cashier_enabled',
    CashierUsername: 'cashier_username',
    CashierOrganization: 'cashier_organization',
    CashierOperators: 'cashier_operators'
} as const;

export type CustomerSettingResponseNameEnum = typeof CustomerSettingResponseNameEnum[keyof typeof CustomerSettingResponseNameEnum];

/**
 * 
 * @export
 * @interface DefaultsResponse
 */
export interface DefaultsResponse {
    /**
     * Invoice note
     * @type {string}
     * @memberof DefaultsResponse
     */
    'invoiceNote'?: string;
}
/**
 * 
 * @export
 * @interface DeviceResponse
 */
export interface DeviceResponse {
    /**
     * Invoicing service electronic device\'s ID
     * @type {string}
     * @memberof DeviceResponse
     */
    'id': string;
    /**
     * Device\'s electronic device ID
     * @type {string}
     * @memberof DeviceResponse
     */
    'electronicDeviceId': string;
    /**
     * Device\'s unique ID
     * @type {string}
     * @memberof DeviceResponse
     */
    'deviceId'?: string;
}
/**
 * 
 * @export
 * @interface EtAccountPassChangeRequest
 */
export interface EtAccountPassChangeRequest {
    /**
     * eTurizem account\'s password
     * @type {string}
     * @memberof EtAccountPassChangeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EtAccountRequest
 */
export interface EtAccountRequest {
    /**
     * eTurizem account\'s username
     * @type {string}
     * @memberof EtAccountRequest
     */
    'username': string;
    /**
     * eTurizem account\'s password
     * @type {string}
     * @memberof EtAccountRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EtAccountResponse
 */
export interface EtAccountResponse {
    /**
     * eTurizem account\'s ID
     * @type {number}
     * @memberof EtAccountResponse
     */
    'id': number;
    /**
     * eTurizem account\'s username
     * @type {string}
     * @memberof EtAccountResponse
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface EtCpDocumentTypeResponse
 */
export interface EtCpDocumentTypeResponse {
    /**
     * Document type\'s ID
     * @type {string}
     * @memberof EtCpDocumentTypeResponse
     */
    'id': string;
    /**
     * Document type\'s name
     * @type {string}
     * @memberof EtCpDocumentTypeResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EtCpReportFacilityStatusResponse
 */
export interface EtCpReportFacilityStatusResponse {
    /**
     * Report facility status\'s ID
     * @type {number}
     * @memberof EtCpReportFacilityStatusResponse
     */
    'id': number;
    /**
     * Report facility status\'s name
     * @type {string}
     * @memberof EtCpReportFacilityStatusResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EtCpTaxPaymentTypeResponse
 */
export interface EtCpTaxPaymentTypeResponse {
    /**
     * Tax payment type\'s ID
     * @type {number}
     * @memberof EtCpTaxPaymentTypeResponse
     */
    'id': number;
    /**
     * Tax payment type\'s name
     * @type {string}
     * @memberof EtCpTaxPaymentTypeResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EtDeleteReportResponse
 */
export interface EtDeleteReportResponse {
    /**
     * eTurizem delete report\'s ID
     * @type {number}
     * @memberof EtDeleteReportResponse
     */
    'id': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof EtDeleteReportResponse
     */
    'facilityId': number;
    /**
     * eTurizem RNO ID
     * @type {string}
     * @memberof EtDeleteReportResponse
     */
    'rnoId': string;
    /**
     * Year of deleted tourist records
     * @type {number}
     * @memberof EtDeleteReportResponse
     */
    'year': number;
    /**
     * Number of deleted tourist records
     * @type {number}
     * @memberof EtDeleteReportResponse
     */
    'deletedRecords': number;
    /**
     * Report\'s date and time
     * @type {string}
     * @memberof EtDeleteReportResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface EtFacilityTaxRequest
 */
export interface EtFacilityTaxRequest {
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof EtFacilityTaxRequest
     */
    'facilityId': number;
    /**
     * Date from tax is valid
     * @type {string}
     * @memberof EtFacilityTaxRequest
     */
    'dateFrom': string;
    /**
     * Full tax value
     * @type {number}
     * @memberof EtFacilityTaxRequest
     */
    'fullTaxValue': number;
    /**
     * Deducted tax value
     * @type {number}
     * @memberof EtFacilityTaxRequest
     */
    'deductedTaxValue': number;
    /**
     * Promotional full tax value
     * @type {number}
     * @memberof EtFacilityTaxRequest
     */
    'promotionFullTaxValue': number;
    /**
     * Promotional deducted tax value
     * @type {number}
     * @memberof EtFacilityTaxRequest
     */
    'promotionDeductedTaxValue': number;
}
/**
 * 
 * @export
 * @interface EtFacilityTaxResponse
 */
export interface EtFacilityTaxResponse {
    /**
     * eTurizem facility tax\'s ID
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'id': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'facilityId': number;
    /**
     * Calculation type
     * @type {string}
     * @memberof EtFacilityTaxResponse
     */
    'calculationType': EtFacilityTaxResponseCalculationTypeEnum;
    /**
     * Date from tax is valid on
     * @type {string}
     * @memberof EtFacilityTaxResponse
     */
    'dateFrom': string;
    /**
     * Full tax value
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'fullTaxValue': number;
    /**
     * Deducted tax value
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'deductedTaxValue': number;
    /**
     * Promotional full tax value
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'promotionFullTaxValue': number;
    /**
     * Promotional deducted tax value
     * @type {number}
     * @memberof EtFacilityTaxResponse
     */
    'promotionDeductedTaxValue': number;
}

export const EtFacilityTaxResponseCalculationTypeEnum = {
    PerPersonPerNight: 'per_person_per_night'
} as const;

export type EtFacilityTaxResponseCalculationTypeEnum = typeof EtFacilityTaxResponseCalculationTypeEnum[keyof typeof EtFacilityTaxResponseCalculationTypeEnum];

/**
 * 
 * @export
 * @interface EtMonthlyReportRequest
 */
export interface EtMonthlyReportRequest {
    /**
     * Monthly report facility status\'s ID
     * @type {number}
     * @memberof EtMonthlyReportRequest
     */
    'reportFacilityStatusId': number;
    /**
     * Number of operating days
     * @type {number}
     * @memberof EtMonthlyReportRequest
     */
    'operatingDays': number;
    /**
     * Total days per room sold
     * @type {number}
     * @memberof EtMonthlyReportRequest
     */
    'soldUnits': number;
    /**
     * Number of extra beds per room sold
     * @type {number}
     * @memberof EtMonthlyReportRequest
     */
    'soldExtraBeds': number;
}
/**
 * 
 * @export
 * @interface EtMonthlyReportResponse
 */
export interface EtMonthlyReportResponse {
    /**
     * eTurizem delete report\'s ID
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'id': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'facilityId': number;
    /**
     * Year and month of report
     * @type {string}
     * @memberof EtMonthlyReportResponse
     */
    'yearMonth': string;
    /**
     * Status of report
     * @type {string}
     * @memberof EtMonthlyReportResponse
     */
    'status': EtMonthlyReportResponseStatusEnum;
    /**
     * ID of eTurizem report facility status
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'reportFacilityStatusId'?: number;
    /**
     * Number of operating days
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'operatingDays': number;
    /**
     * Number of sold units
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'soldUnits': number;
    /**
     * Number of sold extra beds
     * @type {number}
     * @memberof EtMonthlyReportResponse
     */
    'soldExtraBeds': number;
    /**
     * Description of error
     * @type {string}
     * @memberof EtMonthlyReportResponse
     */
    'errorDescription'?: string;
}

export const EtMonthlyReportResponseStatusEnum = {
    Pending: 'pending',
    Sent: 'sent',
    Error: 'error'
} as const;

export type EtMonthlyReportResponseStatusEnum = typeof EtMonthlyReportResponseStatusEnum[keyof typeof EtMonthlyReportResponseStatusEnum];

/**
 * 
 * @export
 * @interface EtTaxReportResponse
 */
export interface EtTaxReportResponse {
    /**
     * Total number of nights for each tourists
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'touristsNights': number;
    /**
     * Number of tourist check-ins
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'touristsCheckIns': number;
    /**
     * Number of full taxes
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'fullTaxes': number;
    /**
     * Number of deducted taxes
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'deductedTaxes': number;
    /**
     * Number of exempt taxes
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'exemptTaxes': number;
    /**
     * Full tax value (promotion included)
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'fullTaxValue': number;
    /**
     * Deducted tax value (promotion included)
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'deductedTaxValue': number;
    /**
     * Sum of tourist taxes
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'taxAmount': number;
    /**
     * Sum of promotion taxes
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'promotionTaxAmount': number;
    /**
     * Total tax sum
     * @type {number}
     * @memberof EtTaxReportResponse
     */
    'totalTaxAmount': number;
}
/**
 * 
 * @export
 * @interface EtTaxesRequest
 */
export interface EtTaxesRequest {
    /**
     * Tourist\'s ID
     * @type {Array<number>}
     * @memberof EtTaxesRequest
     */
    'touristIds': Array<number>;
}
/**
 * 
 * @export
 * @interface EtTouristTaxResponse
 */
export interface EtTouristTaxResponse {
    /**
     * Tourist\'s ID
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'touristId': number;
    /**
     * Tourist\'s serial number
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'serialNumber': number;
    /**
     * Type of tourist\'s tax
     * @type {string}
     * @memberof EtTouristTaxResponse
     */
    'taxType': EtTouristTaxResponseTaxTypeEnum;
    /**
     * Number of nights
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'nights': number;
    /**
     * Number of taxed nights
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'taxedNights': number;
    /**
     * Tax amount per night
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'taxPerNight': number;
    /**
     * Promotion tax amount per night
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'promotionTaxPerNight': number;
    /**
     * Total tax amount per night
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'totalTaxPerNight': number;
    /**
     * Tax amount (for taxed nights)
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'taxAmount': number;
    /**
     * Promotional tax amount (for taxed nights)
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'promotionTaxAmount': number;
    /**
     * Total tax amount (for taxed nights)
     * @type {number}
     * @memberof EtTouristTaxResponse
     */
    'totalTaxAmount': number;
}

export const EtTouristTaxResponseTaxTypeEnum = {
    Full: 'FULL',
    Deducted: 'DEDUCTED',
    Exempt: 'EXEMPT'
} as const;

export type EtTouristTaxResponseTaxTypeEnum = typeof EtTouristTaxResponseTaxTypeEnum[keyof typeof EtTouristTaxResponseTaxTypeEnum];

/**
 * 
 * @export
 * @interface EvAccountPassChangeRequest
 */
export interface EvAccountPassChangeRequest {
    /**
     * eVisitor account\'s password
     * @type {string}
     * @memberof EvAccountPassChangeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EvAccountRequest
 */
export interface EvAccountRequest {
    /**
     * eVisitor account\'s username
     * @type {string}
     * @memberof EvAccountRequest
     */
    'username': string;
    /**
     * eVisitor account\'s password
     * @type {string}
     * @memberof EvAccountRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EvAccountResponse
 */
export interface EvAccountResponse {
    /**
     * eVisitor account\'s ID
     * @type {number}
     * @memberof EvAccountResponse
     */
    'id': number;
    /**
     * eVisitor account\'s username
     * @type {string}
     * @memberof EvAccountResponse
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface EvCpArrivalOrganisationResponse
 */
export interface EvCpArrivalOrganisationResponse {
    /**
     * Arrival organisation\'s ID
     * @type {string}
     * @memberof EvCpArrivalOrganisationResponse
     */
    'id': string;
    /**
     * Arrival organisation\'s code mi
     * @type {string}
     * @memberof EvCpArrivalOrganisationResponse
     */
    'codeMi': string;
    /**
     * Arrival organisation\'s name
     * @type {string}
     * @memberof EvCpArrivalOrganisationResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EvCpDocumentTypeResponse
 */
export interface EvCpDocumentTypeResponse {
    /**
     * Document type\'s ID
     * @type {string}
     * @memberof EvCpDocumentTypeResponse
     */
    'id': string;
    /**
     * Document type\'s code
     * @type {string}
     * @memberof EvCpDocumentTypeResponse
     */
    'code': string;
    /**
     * Document type\'s code mi
     * @type {string}
     * @memberof EvCpDocumentTypeResponse
     */
    'codeMi': string;
    /**
     * Document type\'s name
     * @type {string}
     * @memberof EvCpDocumentTypeResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EvCpOfferedServiceResponse
 */
export interface EvCpOfferedServiceResponse {
    /**
     * Offered service\'s ID
     * @type {string}
     * @memberof EvCpOfferedServiceResponse
     */
    'id': string;
    /**
     * Offered service\'s name
     * @type {string}
     * @memberof EvCpOfferedServiceResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EvCpPaymentCategoryResponse
 */
export interface EvCpPaymentCategoryResponse {
    /**
     * Payment category\'s ID
     * @type {string}
     * @memberof EvCpPaymentCategoryResponse
     */
    'id': string;
    /**
     * Payment category\'s code
     * @type {string}
     * @memberof EvCpPaymentCategoryResponse
     */
    'code': string;
    /**
     * Payment category\'s code names
     * @type {string}
     * @memberof EvCpPaymentCategoryResponse
     */
    'codeNames'?: string;
    /**
     * Payment category\'s name
     * @type {string}
     * @memberof EvCpPaymentCategoryResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EvCpSettlementResponse
 */
export interface EvCpSettlementResponse {
    /**
     * Settlement\'s ID
     * @type {string}
     * @memberof EvCpSettlementResponse
     */
    'id': string;
    /**
     * Settlement\'s city municipality hr ID
     * @type {string}
     * @memberof EvCpSettlementResponse
     */
    'cityMunicipalityHrId': string;
    /**
     * If settlement has zone
     * @type {boolean}
     * @memberof EvCpSettlementResponse
     */
    'hasZones': boolean;
    /**
     * Settlement\'s ZIP code
     * @type {string}
     * @memberof EvCpSettlementResponse
     */
    'zipCode'?: string;
    /**
     * Settlement\'s name
     * @type {string}
     * @memberof EvCpSettlementResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ExceptionRestResponse
 */
export interface ExceptionRestResponse {
    /**
     * 
     * @type {string}
     * @memberof ExceptionRestResponse
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRestResponse
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ExceptionRestResponse
     */
    'additionalData'?: object;
}
/**
 * 
 * @export
 * @interface ExportInvoicesResponse
 */
export interface ExportInvoicesResponse {
    /**
     * Link to exported invoices
     * @type {string}
     * @memberof ExportInvoicesResponse
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface FacilityRequest
 */
export interface FacilityRequest {
    /**
     * Checkout time
     * @type {string}
     * @memberof FacilityRequest
     */
    'checkoutTime'?: string;
    /**
     * Is shown on calendar
     * @type {boolean}
     * @memberof FacilityRequest
     */
    'showOnCalendar': boolean;
}
/**
 * 
 * @export
 * @interface FacilityResponse
 */
export interface FacilityResponse {
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof FacilityResponse
     */
    'id': number;
    /**
     * Facility\'s name
     * @type {string}
     * @memberof FacilityResponse
     */
    'name': string;
    /**
     * Facility\'s type
     * @type {string}
     * @memberof FacilityResponse
     */
    'type'?: string;
    /**
     * Facility\'s settlement
     * @type {string}
     * @memberof FacilityResponse
     */
    'settlement'?: string;
    /**
     * Facility\'s street number
     * @type {string}
     * @memberof FacilityResponse
     */
    'streetNumber'?: string;
    /**
     * Facility\'s settlement zone
     * @type {string}
     * @memberof FacilityResponse
     */
    'settlementZone'?: string;
    /**
     * Facility\'s latitude
     * @type {number}
     * @memberof FacilityResponse
     */
    'latitude'?: number;
    /**
     * Facility\'s longitude
     * @type {number}
     * @memberof FacilityResponse
     */
    'longitude'?: number;
    /**
     * Facility\'s additional info
     * @type {string}
     * @memberof FacilityResponse
     */
    'info'?: string;
    /**
     * Facility\'s checkout time
     * @type {string}
     * @memberof FacilityResponse
     */
    'checkoutTime'?: string;
    /**
     * Is shown on calendar
     * @type {boolean}
     * @memberof FacilityResponse
     */
    'showOnCalendar': boolean;
}
/**
 * 
 * @export
 * @interface FullReservationPartResponse
 */
export interface FullReservationPartResponse {
    /**
     * Reservation part\'s ID
     * @type {number}
     * @memberof FullReservationPartResponse
     */
    'id': number;
    /**
     * Room\'s ID
     * @type {number}
     * @memberof FullReservationPartResponse
     */
    'roomId': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof FullReservationPartResponse
     */
    'facilityId': number;
    /**
     * Reservation part\'s state
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'state': FullReservationPartResponseStateEnum;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'checkOut': string;
    /**
     * Max number of tourists
     * @type {number}
     * @memberof FullReservationPartResponse
     */
    'numberOfTourists'?: number;
    /**
     * Reservation\'s number
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'number': string;
    /**
     * Reservation\'s ID
     * @type {number}
     * @memberof FullReservationPartResponse
     */
    'reservationId': number;
    /**
     * Reservation holder\'s first name
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'holderFirstName': string;
    /**
     * Reservation holder\'s last name
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'holderLastName'?: string;
    /**
     * Reservation holder\'s email
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'holderEmail'?: string;
    /**
     * Reservation holder\'s phone
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'holderTelephone'?: string;
    /**
     * Reservation holder\'s note
     * @type {string}
     * @memberof FullReservationPartResponse
     */
    'holderNote'?: string;
}

export const FullReservationPartResponseStateEnum = {
    Reserved: 'reserved',
    Arrived: 'arrived',
    NoShow: 'no_show',
    Completed: 'completed',
    Cancelled: 'cancelled'
} as const;

export type FullReservationPartResponseStateEnum = typeof FullReservationPartResponseStateEnum[keyof typeof FullReservationPartResponseStateEnum];

/**
 * 
 * @export
 * @interface ImpersonateRequest
 */
export interface ImpersonateRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof ImpersonateRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InvoiceItemRequest
 */
export interface InvoiceItemRequest {
    /**
     * Invoice item\'s name
     * @type {string}
     * @memberof InvoiceItemRequest
     */
    'name': string;
    /**
     * Invoice item\'s gross price
     * @type {number}
     * @memberof InvoiceItemRequest
     */
    'priceGross': number;
    /**
     * Tax\'s ID
     * @type {string}
     * @memberof InvoiceItemRequest
     */
    'taxId'?: string;
    /**
     * Invoice item\'s quantity
     * @type {number}
     * @memberof InvoiceItemRequest
     */
    'quantity': number;
    /**
     * Invoice item\'s discount
     * @type {number}
     * @memberof InvoiceItemRequest
     */
    'discount': number;
    /**
     * Invoice item\'s discount type
     * @type {string}
     * @memberof InvoiceItemRequest
     */
    'discountType': InvoiceItemRequestDiscountTypeEnum;
    /**
     * Invoice item\'s unit
     * @type {string}
     * @memberof InvoiceItemRequest
     */
    'unit'?: string;
}

export const InvoiceItemRequestDiscountTypeEnum = {
    Amount: 'AMOUNT',
    Percent: 'PERCENT'
} as const;

export type InvoiceItemRequestDiscountTypeEnum = typeof InvoiceItemRequestDiscountTypeEnum[keyof typeof InvoiceItemRequestDiscountTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceItemResponse
 */
export interface InvoiceItemResponse {
    /**
     * Invoicing service invoice item\'s ID
     * @type {string}
     * @memberof InvoiceItemResponse
     */
    'id': string;
    /**
     * Invoicing service item\'s ID
     * @type {string}
     * @memberof InvoiceItemResponse
     */
    'itemId'?: string;
    /**
     * Invoice item\'s name
     * @type {string}
     * @memberof InvoiceItemResponse
     */
    'name'?: string;
    /**
     * Invoice item\'s gross price
     * @type {number}
     * @memberof InvoiceItemResponse
     */
    'priceGross': number;
    /**
     * Invoice item\'s total amount
     * @type {number}
     * @memberof InvoiceItemResponse
     */
    'totalAmount': number;
    /**
     * Invoice item\'s quantity
     * @type {number}
     * @memberof InvoiceItemResponse
     */
    'quantity': number;
    /**
     * Invoice item\'s discount
     * @type {number}
     * @memberof InvoiceItemResponse
     */
    'discount'?: number;
    /**
     * Invoice item\'s discount type
     * @type {string}
     * @memberof InvoiceItemResponse
     */
    'discountType': InvoiceItemResponseDiscountTypeEnum;
    /**
     * Invoice item\'s unit
     * @type {string}
     * @memberof InvoiceItemResponse
     */
    'unit'?: string;
}

export const InvoiceItemResponseDiscountTypeEnum = {
    Amount: 'AMOUNT',
    Percent: 'PERCENT'
} as const;

export type InvoiceItemResponseDiscountTypeEnum = typeof InvoiceItemResponseDiscountTypeEnum[keyof typeof InvoiceItemResponseDiscountTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceListResponse
 */
export interface InvoiceListResponse {
    /**
     * Invoicing service invoice\'s ID
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'id': string;
    /**
     * Invoice\'s number
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'number': string;
    /**
     * Invoice\'s state
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'state': InvoiceListResponseStateEnum;
    /**
     * Date of invoice
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'dateOfInvoice': string;
    /**
     * Client name
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'clientName'?: string;
    /**
     * Total amount
     * @type {number}
     * @memberof InvoiceListResponse
     */
    'totalAmount': number;
    /**
     * Business premise\'s ID
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'businessPremiseId'?: string;
}

export const InvoiceListResponseStateEnum = {
    Issued: 'ISSUED',
    Draft: 'DRAFT',
    Canceled: 'CANCELED'
} as const;

export type InvoiceListResponseStateEnum = typeof InvoiceListResponseStateEnum[keyof typeof InvoiceListResponseStateEnum];

/**
 * 
 * @export
 * @interface InvoicePaymentResponse
 */
export interface InvoicePaymentResponse {
    /**
     * Invoicing service invoice payment\'s ID
     * @type {string}
     * @memberof InvoicePaymentResponse
     */
    'id': string;
    /**
     * Invoice item\'s gross price
     * @type {number}
     * @memberof InvoicePaymentResponse
     */
    'amount': number;
    /**
     * Invoice payment\'s type
     * @type {string}
     * @memberof InvoicePaymentResponse
     */
    'type'?: InvoicePaymentResponseTypeEnum;
    /**
     * Invoice payment\'s date
     * @type {string}
     * @memberof InvoicePaymentResponse
     */
    'date': string;
}

export const InvoicePaymentResponseTypeEnum = {
    Bank: 'BANK',
    Online: 'ONLINE',
    Paypal: 'PAYPAL',
    Cash: 'CASH',
    Coupon: 'COUPON',
    Crypto: 'CRYPTO',
    Other: 'OTHER'
} as const;

export type InvoicePaymentResponseTypeEnum = typeof InvoicePaymentResponseTypeEnum[keyof typeof InvoicePaymentResponseTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceRequest
 */
export interface InvoiceRequest {
    /**
     * Reservation\'s ID
     * @type {number}
     * @memberof InvoiceRequest
     */
    'reservationId'?: number;
    /**
     * Business premise\'s ID
     * @type {string}
     * @memberof InvoiceRequest
     */
    'premiseId': string;
    /**
     * Device\'s unique ID
     * @type {string}
     * @memberof InvoiceRequest
     */
    'deviceId': string;
    /**
     * Date of invoice
     * @type {string}
     * @memberof InvoiceRequest
     */
    'dateOfInvoice': string;
    /**
     * Date of service
     * @type {string}
     * @memberof InvoiceRequest
     */
    'dateOfService': string;
    /**
     * End date of service
     * @type {string}
     * @memberof InvoiceRequest
     */
    'dateOfServiceTo'?: string;
    /**
     * Client name
     * @type {string}
     * @memberof InvoiceRequest
     */
    'clientName': string;
    /**
     * Client tax number
     * @type {string}
     * @memberof InvoiceRequest
     */
    'clientTaxNumber'?: string;
    /**
     * Client address
     * @type {string}
     * @memberof InvoiceRequest
     */
    'clientAddress'?: string;
    /**
     * Client ZIP code
     * @type {string}
     * @memberof InvoiceRequest
     */
    'clientZip'?: string;
    /**
     * Client city
     * @type {string}
     * @memberof InvoiceRequest
     */
    'clientCity'?: string;
    /**
     * Client country
     * @type {number}
     * @memberof InvoiceRequest
     */
    'clientCountryId'?: number;
    /**
     * Payment type
     * @type {string}
     * @memberof InvoiceRequest
     */
    'paymentType': InvoiceRequestPaymentTypeEnum;
    /**
     * Pre paid amount
     * @type {number}
     * @memberof InvoiceRequest
     */
    'paidAmount'?: number;
    /**
     * Cashier operator
     * @type {CashierOperatorRequest}
     * @memberof InvoiceRequest
     */
    'cashierOperator': CashierOperatorRequest;
    /**
     * Note
     * @type {string}
     * @memberof InvoiceRequest
     */
    'note'?: string;
    /**
     * Invoice\'s items
     * @type {Array<InvoiceItemRequest>}
     * @memberof InvoiceRequest
     */
    'items': Array<InvoiceItemRequest>;
}

export const InvoiceRequestPaymentTypeEnum = {
    Bank: 'BANK',
    Online: 'ONLINE',
    Paypal: 'PAYPAL',
    Cash: 'CASH',
    Coupon: 'COUPON',
    Crypto: 'CRYPTO',
    Other: 'OTHER'
} as const;

export type InvoiceRequestPaymentTypeEnum = typeof InvoiceRequestPaymentTypeEnum[keyof typeof InvoiceRequestPaymentTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    /**
     * Invoicing service invoice\'s ID
     * @type {string}
     * @memberof InvoiceResponse
     */
    'id': string;
    /**
     * Business premise\'s ID
     * @type {string}
     * @memberof InvoiceResponse
     */
    'businessPremiseId'?: string;
    /**
     * Reservation\'s ID
     * @type {number}
     * @memberof InvoiceResponse
     */
    'reservationId'?: number;
    /**
     * Invoice\'s number
     * @type {string}
     * @memberof InvoiceResponse
     */
    'number': string;
    /**
     * Invoice\'s state
     * @type {string}
     * @memberof InvoiceResponse
     */
    'state': InvoiceResponseStateEnum;
    /**
     * Date of invoice
     * @type {string}
     * @memberof InvoiceResponse
     */
    'dateOfInvoice': string;
    /**
     * Date of service
     * @type {string}
     * @memberof InvoiceResponse
     */
    'dateOfService': string;
    /**
     * End date of service
     * @type {string}
     * @memberof InvoiceResponse
     */
    'dateOfServiceTo'?: string;
    /**
     * Client name
     * @type {string}
     * @memberof InvoiceResponse
     */
    'clientName'?: string;
    /**
     * Client tax number
     * @type {string}
     * @memberof InvoiceResponse
     */
    'clientTaxNumber'?: string;
    /**
     * Client address
     * @type {string}
     * @memberof InvoiceResponse
     */
    'clientAddress'?: string;
    /**
     * Client ZIP code
     * @type {string}
     * @memberof InvoiceResponse
     */
    'clientZip'?: string;
    /**
     * Client city
     * @type {string}
     * @memberof InvoiceResponse
     */
    'clientCity'?: string;
    /**
     * Client country
     * @type {number}
     * @memberof InvoiceResponse
     */
    'clientCountryId'?: number;
    /**
     * Total amount
     * @type {number}
     * @memberof InvoiceResponse
     */
    'totalAmount': number;
    /**
     * Total paid
     * @type {number}
     * @memberof InvoiceResponse
     */
    'totalPaid': number;
    /**
     * Payment type
     * @type {string}
     * @memberof InvoiceResponse
     */
    'paymentType'?: InvoiceResponsePaymentTypeEnum;
    /**
     * Cashier operator
     * @type {CashierOperatorResponse}
     * @memberof InvoiceResponse
     */
    'cashierOperator'?: CashierOperatorResponse;
    /**
     * Note
     * @type {string}
     * @memberof InvoiceResponse
     */
    'note'?: string;
    /**
     * Items
     * @type {Array<InvoiceItemResponse>}
     * @memberof InvoiceResponse
     */
    'items': Array<InvoiceItemResponse>;
    /**
     * Items
     * @type {Array<InvoicePaymentResponse>}
     * @memberof InvoiceResponse
     */
    'payments': Array<InvoicePaymentResponse>;
}

export const InvoiceResponseStateEnum = {
    Issued: 'ISSUED',
    Draft: 'DRAFT',
    Canceled: 'CANCELED'
} as const;

export type InvoiceResponseStateEnum = typeof InvoiceResponseStateEnum[keyof typeof InvoiceResponseStateEnum];
export const InvoiceResponsePaymentTypeEnum = {
    Bank: 'BANK',
    Online: 'ONLINE',
    Paypal: 'PAYPAL',
    Cash: 'CASH',
    Coupon: 'COUPON',
    Crypto: 'CRYPTO',
    Other: 'OTHER'
} as const;

export type InvoiceResponsePaymentTypeEnum = typeof InvoiceResponsePaymentTypeEnum[keyof typeof InvoiceResponsePaymentTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceSendRequest
 */
export interface InvoiceSendRequest {
    /**
     * Recipient\'s email
     * @type {string}
     * @memberof InvoiceSendRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * Invoicing service item\'s ID
     * @type {string}
     * @memberof ItemResponse
     */
    'id': string;
    /**
     * Item\'s name
     * @type {string}
     * @memberof ItemResponse
     */
    'name': string;
    /**
     * Item\'s gross price
     * @type {number}
     * @memberof ItemResponse
     */
    'priceGross'?: number;
    /**
     * Item\'s tax ID
     * @type {string}
     * @memberof ItemResponse
     */
    'taxId'?: string;
    /**
     * Item\'s unit
     * @type {string}
     * @memberof ItemResponse
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface LicencePaymentInfoResponse
 */
export interface LicencePaymentInfoResponse {
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof LicencePaymentInfoResponse
     */
    'facilityId': number;
    /**
     * Facility\'s name
     * @type {string}
     * @memberof LicencePaymentInfoResponse
     */
    'facilityName': string;
    /**
     * Number of beds
     * @type {number}
     * @memberof LicencePaymentInfoResponse
     */
    'beds': number;
    /**
     * Minimum beds in licence
     * @type {number}
     * @memberof LicencePaymentInfoResponse
     */
    'minBeds': number;
    /**
     * Maximum beds in licence
     * @type {number}
     * @memberof LicencePaymentInfoResponse
     */
    'maxBeds': number;
    /**
     * Licence valid from date
     * @type {string}
     * @memberof LicencePaymentInfoResponse
     */
    'validFrom': string;
    /**
     * Licence valid to date
     * @type {string}
     * @memberof LicencePaymentInfoResponse
     */
    'validTo': string;
    /**
     * Licence valid to date
     * @type {number}
     * @memberof LicencePaymentInfoResponse
     */
    'price': number;
    /**
     * Price Id from payment service
     * @type {string}
     * @memberof LicencePaymentInfoResponse
     */
    'priceId': string;
}
/**
 * 
 * @export
 * @interface LicenceRequest
 */
export interface LicenceRequest {
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof LicenceRequest
     */
    'facilityId': number;
    /**
     * Date until licence is valid
     * @type {string}
     * @memberof LicenceRequest
     */
    'validTo': string;
}
/**
 * 
 * @export
 * @interface LicenceResponse
 */
export interface LicenceResponse {
    /**
     * Licence\'s ID
     * @type {number}
     * @memberof LicenceResponse
     */
    'id': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof LicenceResponse
     */
    'facilityId': number;
    /**
     * Number of beds
     * @type {number}
     * @memberof LicenceResponse
     */
    'beds': number;
    /**
     * Licence valid to date
     * @type {string}
     * @memberof LicenceResponse
     */
    'validTo': string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * User\'s password
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * User\'s email
     * @type {string}
     * @memberof LoginResponse
     */
    'email': string;
    /**
     * User\'s JWT token
     * @type {string}
     * @memberof LoginResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MailTokenResendRequest
 */
export interface MailTokenResendRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof MailTokenResendRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MoveTouristsRequest
 */
export interface MoveTouristsRequest {
    /**
     * Room\'s ID
     * @type {number}
     * @memberof MoveTouristsRequest
     */
    'roomId': number;
    /**
     * Date of tourist move
     * @type {string}
     * @memberof MoveTouristsRequest
     */
    'moveDate': string;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * Notification\'s ID
     * @type {number}
     * @memberof NotificationResponse
     */
    'id': number;
    /**
     * Notification\'s read status
     * @type {boolean}
     * @memberof NotificationResponse
     */
    'read': boolean;
    /**
     * Notification\'s type
     * @type {string}
     * @memberof NotificationResponse
     */
    'type': NotificationResponseTypeEnum;
    /**
     * Notification\'s level
     * @type {string}
     * @memberof NotificationResponse
     */
    'level': NotificationResponseLevelEnum;
    /**
     * Notification\'s title
     * @type {string}
     * @memberof NotificationResponse
     */
    'title': string;
    /**
     * Notification\'s additional data
     * @type {AdditionalDataResponse}
     * @memberof NotificationResponse
     */
    'additionalData': AdditionalDataResponse;
    /**
     * Notification\'s date and time
     * @type {string}
     * @memberof NotificationResponse
     */
    'createdAt': string;
}

export const NotificationResponseTypeEnum = {
    EvisitorNoPermissions: 'evisitor-no-permissions',
    EturizemNoPermissions: 'eturizem-no-permissions',
    EturizemAddRooms: 'eturizem-add-rooms',
    EturizemFacilityAlreadyConnected: 'eturizem-facility-already-connected',
    EturizemMonthlyReport: 'eturizem-monthly-report',
    TouristBirthday: 'tourist-birthday',
    LicenceWillExpire: 'licence-will-expire',
    LicenceExpired: 'licence-expired'
} as const;

export type NotificationResponseTypeEnum = typeof NotificationResponseTypeEnum[keyof typeof NotificationResponseTypeEnum];
export const NotificationResponseLevelEnum = {
    Info: 'info',
    Warning: 'warning',
    Error: 'error'
} as const;

export type NotificationResponseLevelEnum = typeof NotificationResponseLevelEnum[keyof typeof NotificationResponseLevelEnum];

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * Organization\'s ID
     * @type {string}
     * @memberof OrganizationResponse
     */
    'id': string;
    /**
     * Organization\'s name
     * @type {string}
     * @memberof OrganizationResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * User\'s password
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PremiseResponse
 */
export interface PremiseResponse {
    /**
     * Invoicing service business premise\'s ID
     * @type {string}
     * @memberof PremiseResponse
     */
    'id': string;
    /**
     * Premise\'s name
     * @type {string}
     * @memberof PremiseResponse
     */
    'name': string;
    /**
     * Premise\'s business premise ID
     * @type {string}
     * @memberof PremiseResponse
     */
    'businessPremiseId': string;
    /**
     * Premise\'s street
     * @type {string}
     * @memberof PremiseResponse
     */
    'street': string;
    /**
     * Premise\'s street
     * @type {string}
     * @memberof PremiseResponse
     */
    'houseNumber': string;
    /**
     * Premise\'s street
     * @type {string}
     * @memberof PremiseResponse
     */
    'postalCode': string;
    /**
     * Premise\'s street
     * @type {string}
     * @memberof PremiseResponse
     */
    'city': string;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof PremiseResponse
     */
    'facilityId'?: number;
    /**
     * List of electronic devices
     * @type {Array<DeviceResponse>}
     * @memberof PremiseResponse
     */
    'devices'?: Array<DeviceResponse>;
}
/**
 * 
 * @export
 * @interface PublicReservationPartResponse
 */
export interface PublicReservationPartResponse {
    /**
     * Reservation part\'s ID
     * @type {number}
     * @memberof PublicReservationPartResponse
     */
    'id': number;
    /**
     * Room\'s name
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'roomName': string;
    /**
     * Facility\'s name
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'facilityName': string;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'checkOut': string;
    /**
     * Max number of tourists
     * @type {number}
     * @memberof PublicReservationPartResponse
     */
    'numberOfTourists': number;
    /**
     * Number of checked-in tourists
     * @type {number}
     * @memberof PublicReservationPartResponse
     */
    'checkedInTourists': number;
    /**
     * Reservation holder\'s first name
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'holderFirstName': string;
    /**
     * Reservation holder\'s last name
     * @type {string}
     * @memberof PublicReservationPartResponse
     */
    'holderLastName'?: string;
}
/**
 * 
 * @export
 * @interface ReferralInfoResponse
 */
export interface ReferralInfoResponse {
    /**
     * Is customer was invited
     * @type {boolean}
     * @memberof ReferralInfoResponse
     */
    'invited': boolean;
    /**
     * Invited customers
     * @type {Array<number>}
     * @memberof ReferralInfoResponse
     */
    'referrals': Array<number>;
    /**
     * Bonus days for trial licence
     * @type {number}
     * @memberof ReferralInfoResponse
     */
    'bonusDays': number;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * User\'s password
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
    /**
     * User\'s phone
     * @type {string}
     * @memberof RegisterRequest
     */
    'phone': string;
    /**
     * User\'s agreement with terms of use
     * @type {boolean}
     * @memberof RegisterRequest
     */
    'agree': boolean;
}
/**
 * 
 * @export
 * @interface ReservationPartRequest
 */
export interface ReservationPartRequest {
    /**
     * Room\'s ID
     * @type {number}
     * @memberof ReservationPartRequest
     */
    'roomId': number;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof ReservationPartRequest
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof ReservationPartRequest
     */
    'checkOut': string;
    /**
     * Max number of tourists
     * @type {number}
     * @memberof ReservationPartRequest
     */
    'numberOfTourists'?: number;
    /**
     * Reservation part\'s tourists check-ins
     * @type {Array<CheckInRequest>}
     * @memberof ReservationPartRequest
     */
    'tourists': Array<CheckInRequest>;
}
/**
 * 
 * @export
 * @interface ReservationPartResponse
 */
export interface ReservationPartResponse {
    /**
     * Reservation part\'s ID
     * @type {number}
     * @memberof ReservationPartResponse
     */
    'id': number;
    /**
     * Reservation\'s ID
     * @type {number}
     * @memberof ReservationPartResponse
     */
    'reservationId': number;
    /**
     * Room\'s ID
     * @type {number}
     * @memberof ReservationPartResponse
     */
    'roomId': number;
    /**
     * Reservation part\'s state
     * @type {string}
     * @memberof ReservationPartResponse
     */
    'state': ReservationPartResponseStateEnum;
    /**
     * Check-in date and time
     * @type {string}
     * @memberof ReservationPartResponse
     */
    'checkIn': string;
    /**
     * Check-out date and time
     * @type {string}
     * @memberof ReservationPartResponse
     */
    'checkOut': string;
    /**
     * Max number of tourists
     * @type {number}
     * @memberof ReservationPartResponse
     */
    'numberOfTourists'?: number;
}

export const ReservationPartResponseStateEnum = {
    Reserved: 'reserved',
    Arrived: 'arrived',
    NoShow: 'no_show',
    Completed: 'completed',
    Cancelled: 'cancelled'
} as const;

export type ReservationPartResponseStateEnum = typeof ReservationPartResponseStateEnum[keyof typeof ReservationPartResponseStateEnum];

/**
 * 
 * @export
 * @interface ReservationRequest
 */
export interface ReservationRequest {
    /**
     * Reservation holder\'s first name
     * @type {string}
     * @memberof ReservationRequest
     */
    'holderFirstName': string;
    /**
     * Reservation holder\'s last name
     * @type {string}
     * @memberof ReservationRequest
     */
    'holderLastName'?: string;
    /**
     * Reservation holder\'s email
     * @type {string}
     * @memberof ReservationRequest
     */
    'holderEmail'?: string;
    /**
     * Reservation holder\'s telephone
     * @type {string}
     * @memberof ReservationRequest
     */
    'holderTelephone'?: string;
    /**
     * Reservation holder\'s note
     * @type {string}
     * @memberof ReservationRequest
     */
    'holderNote'?: string;
    /**
     * Reservation\'s parts
     * @type {Array<ReservationPartRequest>}
     * @memberof ReservationRequest
     */
    'parts': Array<ReservationPartRequest>;
}
/**
 * 
 * @export
 * @interface ReservationResponse
 */
export interface ReservationResponse {
    /**
     * Reservation\'s ID
     * @type {number}
     * @memberof ReservationResponse
     */
    'id': number;
    /**
     * Reservation\'s number
     * @type {string}
     * @memberof ReservationResponse
     */
    'number': string;
    /**
     * Reservation holder\'s first name
     * @type {string}
     * @memberof ReservationResponse
     */
    'holderFirstName': string;
    /**
     * Reservation holder\'s last name
     * @type {string}
     * @memberof ReservationResponse
     */
    'holderLastName'?: string;
    /**
     * Reservation holder\'s email
     * @type {string}
     * @memberof ReservationResponse
     */
    'holderEmail'?: string;
    /**
     * Reservation holder\'s telephone
     * @type {string}
     * @memberof ReservationResponse
     */
    'holderTelephone'?: string;
    /**
     * Reservation holder\'s note
     * @type {string}
     * @memberof ReservationResponse
     */
    'holderNote'?: string;
}
/**
 * 
 * @export
 * @interface RoomRequest
 */
export interface RoomRequest {
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof RoomRequest
     */
    'facilityId': number;
    /**
     * Name
     * @type {string}
     * @memberof RoomRequest
     */
    'name': string;
    /**
     * Number of beds
     * @type {number}
     * @memberof RoomRequest
     */
    'basicBeds': number;
    /**
     * Number of extra beds
     * @type {number}
     * @memberof RoomRequest
     */
    'extraBeds': number;
    /**
     * Type
     * @type {string}
     * @memberof RoomRequest
     */
    'type'?: string;
    /**
     * Is shown on calendar
     * @type {boolean}
     * @memberof RoomRequest
     */
    'showOnCalendar': boolean;
}
/**
 * 
 * @export
 * @interface RoomResponse
 */
export interface RoomResponse {
    /**
     * Room\'s ID
     * @type {number}
     * @memberof RoomResponse
     */
    'id': number;
    /**
     * Facility\'s ID
     * @type {number}
     * @memberof RoomResponse
     */
    'facilityId': number;
    /**
     * Room\'s name
     * @type {string}
     * @memberof RoomResponse
     */
    'name': string;
    /**
     * Number of beds
     * @type {number}
     * @memberof RoomResponse
     */
    'basicBeds': number;
    /**
     * Number of extra beds
     * @type {number}
     * @memberof RoomResponse
     */
    'extraBeds': number;
    /**
     * Room\'s type
     * @type {string}
     * @memberof RoomResponse
     */
    'type'?: string;
    /**
     * Is shown on calendar
     * @type {boolean}
     * @memberof RoomResponse
     */
    'showOnCalendar': boolean;
}
/**
 * 
 * @export
 * @interface SettingRequest
 */
export interface SettingRequest {
    /**
     * Setting\'s value
     * @type {string}
     * @memberof SettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SettingResponse
 */
export interface SettingResponse {
    /**
     * Setting\'s ID
     * @type {number}
     * @memberof SettingResponse
     */
    'id': number;
    /**
     * Setting\'s name
     * @type {string}
     * @memberof SettingResponse
     */
    'name': SettingResponseNameEnum;
    /**
     * Setting\'s value
     * @type {string}
     * @memberof SettingResponse
     */
    'value': string;
}

export const SettingResponseNameEnum = {
    CheckoutTime: 'checkout_time',
    StayDuration: 'stay_duration'
} as const;

export type SettingResponseNameEnum = typeof SettingResponseNameEnum[keyof typeof SettingResponseNameEnum];

/**
 * 
 * @export
 * @interface SliceInvoiceListResponse
 */
export interface SliceInvoiceListResponse {
    /**
     * 
     * @type {number}
     * @memberof SliceInvoiceListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<InvoiceListResponse>}
     * @memberof SliceInvoiceListResponse
     */
    'content'?: Array<InvoiceListResponse>;
    /**
     * 
     * @type {number}
     * @memberof SliceInvoiceListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof SliceInvoiceListResponse
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof SliceInvoiceListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliceInvoiceListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SliceInvoiceListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof SliceInvoiceListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof SliceInvoiceListResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface SliceNotificationResponse
 */
export interface SliceNotificationResponse {
    /**
     * 
     * @type {number}
     * @memberof SliceNotificationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<NotificationResponse>}
     * @memberof SliceNotificationResponse
     */
    'content'?: Array<NotificationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SliceNotificationResponse
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof SliceNotificationResponse
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof SliceNotificationResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliceNotificationResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SliceNotificationResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof SliceNotificationResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof SliceNotificationResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
    /**
     * Operation status
     * @type {boolean}
     * @memberof StatusResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionRequest
 */
export interface SubscriptionRequest {
    /**
     * Facility IDs
     * @type {Array<number>}
     * @memberof SubscriptionRequest
     */
    'facilityIds': Array<number>;
    /**
     * Payment type
     * @type {string}
     * @memberof SubscriptionRequest
     */
    'paymentMethod': SubscriptionRequestPaymentMethodEnum;
    /**
     * Coupon code
     * @type {string}
     * @memberof SubscriptionRequest
     */
    'couponCode'?: string;
}

export const SubscriptionRequestPaymentMethodEnum = {
    Card: 'CARD',
    BankTransfer: 'BANK_TRANSFER'
} as const;

export type SubscriptionRequestPaymentMethodEnum = typeof SubscriptionRequestPaymentMethodEnum[keyof typeof SubscriptionRequestPaymentMethodEnum];

/**
 * 
 * @export
 * @interface SubscriptionResponse
 */
export interface SubscriptionResponse {
    /**
     * Subscription\'s ID
     * @type {number}
     * @memberof SubscriptionResponse
     */
    'id': number;
    /**
     * Customer\'s ID
     * @type {number}
     * @memberof SubscriptionResponse
     */
    'customerId': number;
    /**
     * State of subscription
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'state': SubscriptionResponseStateEnum;
    /**
     * Payment service subscription\'s ID
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'serviceId': string;
    /**
     * Payment service customer\'s ID
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'serviceCustomerId': string;
    /**
     * Payment service payment secret
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'serviceClientSecret': string;
    /**
     * Payment service coupon\'s ID
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'serviceCouponId'?: string;
    /**
     * Subscription end date
     * @type {string}
     * @memberof SubscriptionResponse
     */
    'endsAt': string;
    /**
     * Subscription\'s licences
     * @type {Array<LicenceResponse>}
     * @memberof SubscriptionResponse
     */
    'licences': Array<LicenceResponse>;
}

export const SubscriptionResponseStateEnum = {
    Pending: 'pending',
    Trial: 'trial',
    Active: 'active',
    Cancelled: 'cancelled'
} as const;

export type SubscriptionResponseStateEnum = typeof SubscriptionResponseStateEnum[keyof typeof SubscriptionResponseStateEnum];

/**
 * 
 * @export
 * @interface TouristEventResponse
 */
export interface TouristEventResponse {
    /**
     * Tourist event\'s ID
     * @type {number}
     * @memberof TouristEventResponse
     */
    'id': number;
    /**
     * Tourist\'s ID
     * @type {number}
     * @memberof TouristEventResponse
     */
    'touristId': number;
    /**
     * User\'s ID who triggered action
     * @type {number}
     * @memberof TouristEventResponse
     */
    'triggeredBy'?: number;
    /**
     * Event type
     * @type {string}
     * @memberof TouristEventResponse
     */
    'type': TouristEventResponseTypeEnum;
    /**
     * Created date and time
     * @type {string}
     * @memberof TouristEventResponse
     */
    'createdAt': string;
    /**
     * Executed date and time
     * @type {string}
     * @memberof TouristEventResponse
     */
    'executedAt'?: string;
    /**
     * Failed date and time
     * @type {string}
     * @memberof TouristEventResponse
     */
    'failedAt'?: string;
    /**
     * Failure reason message
     * @type {string}
     * @memberof TouristEventResponse
     */
    'failureReason'?: string;
    /**
     * Failure type
     * @type {string}
     * @memberof TouristEventResponse
     */
    'failureType'?: TouristEventResponseFailureTypeEnum;
}

export const TouristEventResponseTypeEnum = {
    Checkin: 'checkin',
    CheckinCancel: 'checkin_cancel',
    ModifyAtAuthority: 'modify_at_authority',
    ModifyLocally: 'modify_locally',
    Checkout: 'checkout',
    CheckoutCancel: 'checkout_cancel'
} as const;

export type TouristEventResponseTypeEnum = typeof TouristEventResponseTypeEnum[keyof typeof TouristEventResponseTypeEnum];
export const TouristEventResponseFailureTypeEnum = {
    System: 'system',
    User: 'user'
} as const;

export type TouristEventResponseFailureTypeEnum = typeof TouristEventResponseFailureTypeEnum[keyof typeof TouristEventResponseFailureTypeEnum];

/**
 * 
 * @export
 * @interface TouristResponse
 */
export interface TouristResponse {
    /**
     * Tourist\'s ID
     * @type {number}
     * @memberof TouristResponse
     */
    'id': number;
    /**
     * Reservation part\'s ID
     * @type {number}
     * @memberof TouristResponse
     */
    'reservationPartId': number;
    /**
     * Tourist\'s state
     * @type {string}
     * @memberof TouristResponse
     */
    'state': TouristResponseStateEnum;
    /**
     * Check-in date time
     * @type {string}
     * @memberof TouristResponse
     */
    'checkIn': string;
    /**
     * Check-out date time
     * @type {string}
     * @memberof TouristResponse
     */
    'checkOut': string;
    /**
     * Tourist\'s first name
     * @type {string}
     * @memberof TouristResponse
     */
    'firstName': string;
    /**
     * Tourist\'s last name
     * @type {string}
     * @memberof TouristResponse
     */
    'lastName': string;
    /**
     * Tourist\'s gender
     * @type {string}
     * @memberof TouristResponse
     */
    'gender': TouristResponseGenderEnum;
    /**
     * Tourist\'s document number
     * @type {string}
     * @memberof TouristResponse
     */
    'documentNumber': string;
    /**
     * Tourist\'s document type
     * @type {string}
     * @memberof TouristResponse
     */
    'documentType': string;
    /**
     * Tourist\'s country of birth
     * @type {number}
     * @memberof TouristResponse
     */
    'countryOfBirthId': number;
    /**
     * Tourist\'s citizenship
     * @type {number}
     * @memberof TouristResponse
     */
    'countryOfCitizenshipId': number;
    /**
     * Tourist\'s country of residence
     * @type {number}
     * @memberof TouristResponse
     */
    'countryOfResidenceId': number;
    /**
     * Tourist\'s residence city
     * @type {string}
     * @memberof TouristResponse
     */
    'cityOfResidence'?: string;
    /**
     * Tourist\'s date of birth
     * @type {string}
     * @memberof TouristResponse
     */
    'dateOfBirth': string;
    /**
     * Tourist\'s city of birth
     * @type {string}
     * @memberof TouristResponse
     */
    'cityOfBirth'?: string;
    /**
     * Tourist\'s telephone
     * @type {string}
     * @memberof TouristResponse
     */
    'telephone'?: string;
    /**
     * Tourist\'s email
     * @type {string}
     * @memberof TouristResponse
     */
    'email'?: string;
    /**
     * Offered service
     * @type {string}
     * @memberof TouristResponse
     */
    'offeredService'?: string;
    /**
     * Arrival organisation type
     * @type {string}
     * @memberof TouristResponse
     */
    'arrivalOrganisation'?: string;
    /**
     * Facility\'s tax payment category
     * @type {string}
     * @memberof TouristResponse
     */
    'paymentCategory'?: string;
    /**
     * Facility\'s tax payment category description
     * @type {string}
     * @memberof TouristResponse
     */
    'paymentCategoryDescription'?: string;
    /**
     * Synchronisation status
     * @type {string}
     * @memberof TouristResponse
     */
    'syncStatus': TouristResponseSyncStatusEnum;
    /**
     * Synchronisation message
     * @type {string}
     * @memberof TouristResponse
     */
    'syncMessage': string;
}

export const TouristResponseStateEnum = {
    CheckedIn: 'checked_in',
    CheckedOut: 'checked_out',
    Cancelled: 'cancelled',
    Draft: 'draft'
} as const;

export type TouristResponseStateEnum = typeof TouristResponseStateEnum[keyof typeof TouristResponseStateEnum];
export const TouristResponseGenderEnum = {
    Male: 'male',
    Female: 'female'
} as const;

export type TouristResponseGenderEnum = typeof TouristResponseGenderEnum[keyof typeof TouristResponseGenderEnum];
export const TouristResponseSyncStatusEnum = {
    Ok: 'OK',
    Error: 'ERROR',
    ImportedFromAuthority: 'IMPORTED_FROM_AUTHORITY',
    ChangedAtAuthority: 'CHANGED_AT_AUTHORITY'
} as const;

export type TouristResponseSyncStatusEnum = typeof TouristResponseSyncStatusEnum[keyof typeof TouristResponseSyncStatusEnum];

/**
 * 
 * @export
 * @interface UpdateReservationRequest
 */
export interface UpdateReservationRequest {
    /**
     * Reservation holder\'s first name
     * @type {string}
     * @memberof UpdateReservationRequest
     */
    'holderFirstName': string;
    /**
     * Reservation holder\'s last name
     * @type {string}
     * @memberof UpdateReservationRequest
     */
    'holderLastName'?: string;
    /**
     * Reservation holder\'s email
     * @type {string}
     * @memberof UpdateReservationRequest
     */
    'holderEmail'?: string;
    /**
     * Reservation holder\'s telephone
     * @type {string}
     * @memberof UpdateReservationRequest
     */
    'holderTelephone'?: string;
    /**
     * Reservation holder\'s note
     * @type {string}
     * @memberof UpdateReservationRequest
     */
    'holderNote'?: string;
}
/**
 * 
 * @export
 * @interface UserAdminResponse
 */
export interface UserAdminResponse {
    /**
     * User\'s ID
     * @type {number}
     * @memberof UserAdminResponse
     */
    'id': number;
    /**
     * Customer\'s ID
     * @type {number}
     * @memberof UserAdminResponse
     */
    'customerId': number;
    /**
     * User\'s email
     * @type {string}
     * @memberof UserAdminResponse
     */
    'email': string;
    /**
     * User\'s email confirmed
     * @type {boolean}
     * @memberof UserAdminResponse
     */
    'emailConfirmed': boolean;
    /**
     * User\'s role
     * @type {string}
     * @memberof UserAdminResponse
     */
    'role': string;
    /**
     * User\'s last login device
     * @type {string}
     * @memberof UserAdminResponse
     */
    'lastLoginDevice': string;
    /**
     * User\'s last login date and time
     * @type {string}
     * @memberof UserAdminResponse
     */
    'lastLoginAt': string;
    /**
     * User\'s created date and time
     * @type {string}
     * @memberof UserAdminResponse
     */
    'createdAt': string;
    /**
     * User\'s deleted status
     * @type {boolean}
     * @memberof UserAdminResponse
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface UserMailRequest
 */
export interface UserMailRequest {
    /**
     * User\'s email
     * @type {string}
     * @memberof UserMailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * User\'s ID
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * Customer\'s ID
     * @type {number}
     * @memberof UserResponse
     */
    'customerId': number;
    /**
     * User\'s email
     * @type {string}
     * @memberof UserResponse
     */
    'email': string;
    /**
     * User\'s role
     * @type {string}
     * @memberof UserResponse
     */
    'role': string;
}

/**
 * AdministrationApi - axios parameter creator
 * @export
 */
export const AdministrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export data (hosts, facilities, users, referrals) in CSV
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export: async (type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('_export', 'type', type)
            const localVarPath = `/private/export/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create customer\'s licence
         * @param {number} id 
         * @param {LicenceRequest} licenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerLicences: async (id: number, licenceRequest: LicenceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createCustomerLicences', 'id', id)
            // verify required parameter 'licenceRequest' is not null or undefined
            assertParamExists('createCustomerLicences', 'licenceRequest', licenceRequest)
            const localVarPath = `/private/customers/{id}/licences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer\'s facilities
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerFacilities: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listCustomerFacilities', 'id', id)
            const localVarPath = `/private/customers/{id}/facilities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer\'s licences
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerLicences: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listCustomerLicences', 'id', id)
            const localVarPath = `/private/customers/{id}/licences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer\'s users
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerUsers: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listCustomerUsers', 'id', id)
            const localVarPath = `/private/customers/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/private/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Undelete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undeleteUser: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('undeleteUser', 'id', id)
            const localVarPath = `/private/users/{id}/undelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {number} id 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordChange: async (id: number, passwordChangeRequest: PasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userPasswordChange', 'id', id)
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('userPasswordChange', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/private/users/{id}/password/change`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministrationApi - functional programming interface
 * @export
 */
export const AdministrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdministrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export data (hosts, facilities, users, referrals) in CSV
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _export(type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._export(type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi._export']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create customer\'s licence
         * @param {number} id 
         * @param {LicenceRequest} licenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerLicences(id: number, licenceRequest: LicenceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerLicences(id, licenceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.createCustomerLicences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer\'s facilities
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerFacilities(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerFacilities(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.listCustomerFacilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer\'s licences
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerLicences(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerLicences(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.listCustomerLicences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer\'s users
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerUsers(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAdminResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerUsers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.listCustomerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.listCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Undelete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undeleteUser(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undeleteUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.undeleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {number} id 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPasswordChange(id: number, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPasswordChange(id, passwordChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdministrationApi.userPasswordChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdministrationApi - factory interface
 * @export
 */
export const AdministrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdministrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Export data (hosts, facilities, users, referrals) in CSV
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export(type: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp._export(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create customer\'s licence
         * @param {number} id 
         * @param {LicenceRequest} licenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerLicences(id: number, licenceRequest: LicenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<LicenceResponse> {
            return localVarFp.createCustomerLicences(id, licenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer\'s facilities
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerFacilities(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<FacilityResponse>> {
            return localVarFp.listCustomerFacilities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer\'s licences
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerLicences(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<LicenceResponse>> {
            return localVarFp.listCustomerLicences(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer\'s users
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerUsers(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserAdminResponse>> {
            return localVarFp.listCustomerUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomers(options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerResponse>> {
            return localVarFp.listCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Undelete user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undeleteUser(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.undeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {number} id 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPasswordChange(id: number, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.userPasswordChange(id, passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdministrationApi - object-oriented interface
 * @export
 * @class AdministrationApi
 * @extends {BaseAPI}
 */
export class AdministrationApi extends BaseAPI {
    /**
     * 
     * @summary Export data (hosts, facilities, users, referrals) in CSV
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public _export(type: string, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration)._export(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create customer\'s licence
     * @param {number} id 
     * @param {LicenceRequest} licenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public createCustomerLicences(id: number, licenceRequest: LicenceRequest, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).createCustomerLicences(id, licenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer\'s facilities
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public listCustomerFacilities(id: number, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).listCustomerFacilities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer\'s licences
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public listCustomerLicences(id: number, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).listCustomerLicences(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer\'s users
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public listCustomerUsers(id: number, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).listCustomerUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public listCustomers(options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).listCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Undelete user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public undeleteUser(id: number, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).undeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change user\'s password
     * @param {number} id 
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public userPasswordChange(id: number, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig) {
        return AdministrationApiFp(this.configuration).userPasswordChange(id, passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CashierApi - axios parameter creator
 * @export
 */
export const CashierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate cashier
         * @param {CashierActivationRequest} cashierActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCashier: async (cashierActivationRequest: CashierActivationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashierActivationRequest' is not null or undefined
            assertParamExists('activateCashier', 'cashierActivationRequest', cashierActivationRequest)
            const localVarPath = `/protected/cashier/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashierActivationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cashier defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaults: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/cashier/defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List cashier items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItems: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/cashier/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List cashier organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/cashier/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List business premises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPremises: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/cashier/premises`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashierApi - functional programming interface
 * @export
 */
export const CashierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashierApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate cashier
         * @param {CashierActivationRequest} cashierActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCashier(cashierActivationRequest: CashierActivationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCashier(cashierActivationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.activateCashier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get cashier defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaults(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaults(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.getDefaults']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List cashier items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listItems(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listItems(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.listItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List cashier organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.listOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List business premises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPremises(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PremiseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPremises(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.listPremises']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashierApi - factory interface
 * @export
 */
export const CashierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashierApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate cashier
         * @param {CashierActivationRequest} cashierActivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCashier(cashierActivationRequest: CashierActivationRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.activateCashier(cashierActivationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cashier defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaults(options?: RawAxiosRequestConfig): AxiosPromise<DefaultsResponse> {
            return localVarFp.getDefaults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List cashier items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItems(options?: RawAxiosRequestConfig): AxiosPromise<Array<ItemResponse>> {
            return localVarFp.listItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List cashier organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.listOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List business premises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPremises(options?: RawAxiosRequestConfig): AxiosPromise<Array<PremiseResponse>> {
            return localVarFp.listPremises(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashierApi - object-oriented interface
 * @export
 * @class CashierApi
 * @extends {BaseAPI}
 */
export class CashierApi extends BaseAPI {
    /**
     * 
     * @summary Activate cashier
     * @param {CashierActivationRequest} cashierActivationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public activateCashier(cashierActivationRequest: CashierActivationRequest, options?: RawAxiosRequestConfig) {
        return CashierApiFp(this.configuration).activateCashier(cashierActivationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cashier defaults
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public getDefaults(options?: RawAxiosRequestConfig) {
        return CashierApiFp(this.configuration).getDefaults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List cashier items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public listItems(options?: RawAxiosRequestConfig) {
        return CashierApiFp(this.configuration).listItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List cashier organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public listOrganizations(options?: RawAxiosRequestConfig) {
        return CashierApiFp(this.configuration).listOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List business premises
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public listPremises(options?: RawAxiosRequestConfig) {
        return CashierApiFp(this.configuration).listPremises(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCountries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCountries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountryApi.listCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @summary List countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCountries(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryResponse>> {
            return localVarFp.listCountries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @summary List countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public listCountries(options?: RawAxiosRequestConfig) {
        return CountryApiFp(this.configuration).listCountries(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Connect user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUser: async (userMailRequest: UserMailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMailRequest' is not null or undefined
            assertParamExists('connectUser', 'userMailRequest', userMailRequest)
            const localVarPath = `/protected/customers/me/connect/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authenticated user\'s customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCustomer: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/customers/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: async (userMailRequest: UserMailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMailRequest' is not null or undefined
            assertParamExists('inviteUser', 'userMailRequest', userMailRequest)
            const localVarPath = `/protected/customers/me/invite/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List authenticated user\'s customer users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuthCustomerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/customers/me/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update authenticated user\'s customer
         * @param {CustomerRequest} customerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthCustomer: async (customerRequest: CustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerRequest' is not null or undefined
            assertParamExists('updateAuthCustomer', 'customerRequest', customerRequest)
            const localVarPath = `/protected/customers/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Connect user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectUser(userMailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.connectUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get authenticated user\'s customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthCustomer(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthCustomer(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getAuthCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invite user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUser(userMailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.inviteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List authenticated user\'s customer users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAuthCustomerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAuthCustomerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.listAuthCustomerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update authenticated user\'s customer
         * @param {CustomerRequest} customerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthCustomer(customerRequest: CustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthCustomer(customerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateAuthCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Connect user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.connectUser(userMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authenticated user\'s customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCustomer(options?: RawAxiosRequestConfig): AxiosPromise<CustomerResponse> {
            return localVarFp.getAuthCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite user to authenticated user\'s customer
         * @param {UserMailRequest} userMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.inviteUser(userMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List authenticated user\'s customer users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuthCustomerUsers(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserResponse>> {
            return localVarFp.listAuthCustomerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update authenticated user\'s customer
         * @param {CustomerRequest} customerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthCustomer(customerRequest: CustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerResponse> {
            return localVarFp.updateAuthCustomer(customerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Connect user to authenticated user\'s customer
     * @param {UserMailRequest} userMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public connectUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).connectUser(userMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authenticated user\'s customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getAuthCustomer(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getAuthCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite user to authenticated user\'s customer
     * @param {UserMailRequest} userMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public inviteUser(userMailRequest: UserMailRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).inviteUser(userMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List authenticated user\'s customer users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public listAuthCustomerUsers(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).listAuthCustomerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update authenticated user\'s customer
     * @param {CustomerRequest} customerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateAuthCustomer(customerRequest: CustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateAuthCustomer(customerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ETurizemApi - axios parameter creator
 * @export
 */
export const ETurizemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add eTurizem account
         * @param {EtAccountRequest} etAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEtAccount: async (etAccountRequest: EtAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'etAccountRequest' is not null or undefined
            assertParamExists('addEtAccount', 'etAccountRequest', etAccountRequest)
            const localVarPath = `/protected/eturizem/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change eTurizem account\'s password
         * @param {number} id 
         * @param {EtAccountPassChangeRequest} etAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEtAccountPass: async (id: number, etAccountPassChangeRequest: EtAccountPassChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeEtAccountPass', 'id', id)
            // verify required parameter 'etAccountPassChangeRequest' is not null or undefined
            assertParamExists('changeEtAccountPass', 'etAccountPassChangeRequest', etAccountPassChangeRequest)
            const localVarPath = `/protected/eturizem/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etAccountPassChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create eTurizem facility tax
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEtFacilityTax: async (etFacilityTaxRequest: EtFacilityTaxRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'etFacilityTaxRequest' is not null or undefined
            assertParamExists('createEtFacilityTax', 'etFacilityTaxRequest', etFacilityTaxRequest)
            const localVarPath = `/protected/eturizem/facility/taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etFacilityTaxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerEtAccounts: async (customerId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomerEtAccounts', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerEtAccounts', 'id', id)
            const localVarPath = `/private/customers/{customerId}/eturizem/accounts/{id}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEtAccount', 'id', id)
            const localVarPath = `/protected/eturizem/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete eTurizem facility tax
         * @param {number} id 
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtFacilityTax: async (id: number, facility: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEtFacilityTax', 'id', id)
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('deleteEtFacilityTax', 'facility', facility)
            const localVarPath = `/protected/eturizem/facility/taxes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export eTurizem tourists to PDF
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEtTourists: async (facility: number, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('exportEtTourists', 'facility', facility)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportEtTourists', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportEtTourists', 'to', to)
            const localVarPath = `/protected/eturizem/tourists/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem tax report
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtTaxReport: async (facility: number, from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('getEtTaxReport', 'facility', facility)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getEtTaxReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getEtTaxReport', 'to', to)
            const localVarPath = `/protected/eturizem/reports/tax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer\'s eturizem accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerEtAccounts: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listCustomerEtAccounts', 'id', id)
            const localVarPath = `/private/customers/{id}/eturizem/accounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/eturizem/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem delete report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtDeleteReports: async (facility: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('listEtDeleteReports', 'facility', facility)
            const localVarPath = `/protected/eturizem/reports/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtDocumentTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/eturizem/document/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem facility tax list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtFacilityTaxes: async (facility: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('listEtFacilityTaxes', 'facility', facility)
            const localVarPath = `/protected/eturizem/facility/taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem monthly report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtMonthlyReports: async (facility: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('listEtMonthlyReports', 'facility', facility)
            const localVarPath = `/protected/eturizem/reports/monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report facility statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtReportFacilityStatuses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/eturizem/report/facility/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tax payment types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtTaxPaymentTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/eturizem/tax/payment/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {Array<number>} tourists 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listEtTouristsTaxes: async (tourists: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tourists' is not null or undefined
            assertParamExists('listEtTouristsTaxes', 'tourists', tourists)
            const localVarPath = `/protected/eturizem/tourists/taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tourists) {
                localVarQueryParameter['tourists'] = tourists;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {EtTaxesRequest} etTaxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtTouristsTaxes1: async (etTaxesRequest: EtTaxesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'etTaxesRequest' is not null or undefined
            assertParamExists('listEtTouristsTaxes1', 'etTaxesRequest', etTaxesRequest)
            const localVarPath = `/protected/eturizem/tourists/taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etTaxesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEtAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncEtAccount', 'id', id)
            const localVarPath = `/protected/eturizem/accounts/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update eTurizem facility tax
         * @param {number} id 
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtFacilityTax: async (id: number, etFacilityTaxRequest: EtFacilityTaxRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEtFacilityTax', 'id', id)
            // verify required parameter 'etFacilityTaxRequest' is not null or undefined
            assertParamExists('updateEtFacilityTax', 'etFacilityTaxRequest', etFacilityTaxRequest)
            const localVarPath = `/protected/eturizem/facility/taxes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etFacilityTaxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update eTurizem monthly report and send it to authority
         * @param {number} id 
         * @param {EtMonthlyReportRequest} etMonthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtMonthlyReport: async (id: number, etMonthlyReportRequest: EtMonthlyReportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEtMonthlyReport', 'id', id)
            // verify required parameter 'etMonthlyReportRequest' is not null or undefined
            assertParamExists('updateEtMonthlyReport', 'etMonthlyReportRequest', etMonthlyReportRequest)
            const localVarPath = `/protected/eturizem/reports/monthly/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etMonthlyReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ETurizemApi - functional programming interface
 * @export
 */
export const ETurizemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ETurizemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add eTurizem account
         * @param {EtAccountRequest} etAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEtAccount(etAccountRequest: EtAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEtAccount(etAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.addEtAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change eTurizem account\'s password
         * @param {number} id 
         * @param {EtAccountPassChangeRequest} etAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEtAccountPass(id: number, etAccountPassChangeRequest: EtAccountPassChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEtAccountPass(id, etAccountPassChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.changeEtAccountPass']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create eTurizem facility tax
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEtFacilityTax(etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtFacilityTaxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEtFacilityTax(etFacilityTaxRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.createEtFacilityTax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerEtAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerEtAccounts(customerId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.deleteCustomerEtAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEtAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEtAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.deleteEtAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete eTurizem facility tax
         * @param {number} id 
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEtFacilityTax(id: number, facility: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEtFacilityTax(id, facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.deleteEtFacilityTax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export eTurizem tourists to PDF
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEtTourists(facility: number, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEtTourists(facility, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.exportEtTourists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem tax report
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEtTaxReport(facility: number, from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtTaxReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEtTaxReport(facility, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.getEtTaxReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer\'s eturizem accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerEtAccounts(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerEtAccounts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listCustomerEtAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem delete report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtDeleteReports(facility: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtDeleteReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtDeleteReports(facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtDeleteReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtDocumentTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtCpDocumentTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtDocumentTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtDocumentTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem facility tax list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtFacilityTaxes(facility: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtFacilityTaxResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtFacilityTaxes(facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtFacilityTaxes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem monthly report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtMonthlyReports(facility: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtMonthlyReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtMonthlyReports(facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtMonthlyReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get report facility statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtReportFacilityStatuses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtCpReportFacilityStatusResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtReportFacilityStatuses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtReportFacilityStatuses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get tax payment types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtTaxPaymentTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtCpTaxPaymentTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtTaxPaymentTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtTaxPaymentTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {Array<number>} tourists 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listEtTouristsTaxes(tourists: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtTouristTaxResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtTouristsTaxes(tourists, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtTouristsTaxes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {EtTaxesRequest} etTaxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEtTouristsTaxes1(etTaxesRequest: EtTaxesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EtTouristTaxResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEtTouristsTaxes1(etTaxesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.listEtTouristsTaxes1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncEtAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncEtAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.syncEtAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update eTurizem facility tax
         * @param {number} id 
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtFacilityTax(id: number, etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtFacilityTaxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtFacilityTax(id, etFacilityTaxRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.updateEtFacilityTax']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update eTurizem monthly report and send it to authority
         * @param {number} id 
         * @param {EtMonthlyReportRequest} etMonthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtMonthlyReport(id: number, etMonthlyReportRequest: EtMonthlyReportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtMonthlyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtMonthlyReport(id, etMonthlyReportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ETurizemApi.updateEtMonthlyReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ETurizemApi - factory interface
 * @export
 */
export const ETurizemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ETurizemApiFp(configuration)
    return {
        /**
         * 
         * @summary Add eTurizem account
         * @param {EtAccountRequest} etAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEtAccount(etAccountRequest: EtAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<EtAccountResponse> {
            return localVarFp.addEtAccount(etAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change eTurizem account\'s password
         * @param {number} id 
         * @param {EtAccountPassChangeRequest} etAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEtAccountPass(id: number, etAccountPassChangeRequest: EtAccountPassChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.changeEtAccountPass(id, etAccountPassChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create eTurizem facility tax
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEtFacilityTax(etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig): AxiosPromise<EtFacilityTaxResponse> {
            return localVarFp.createEtFacilityTax(etFacilityTaxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerEtAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteCustomerEtAccounts(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtAccount(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteEtAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete eTurizem facility tax
         * @param {number} id 
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtFacilityTax(id: number, facility: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteEtFacilityTax(id, facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export eTurizem tourists to PDF
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEtTourists(facility: number, from: string, to: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.exportEtTourists(facility, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem tax report
         * @param {number} facility 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtTaxReport(facility: number, from: string, to: string, options?: RawAxiosRequestConfig): AxiosPromise<EtTaxReportResponse> {
            return localVarFp.getEtTaxReport(facility, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer\'s eturizem accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerEtAccounts(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtAccountResponse>> {
            return localVarFp.listCustomerEtAccounts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtAccounts(options?: RawAxiosRequestConfig): AxiosPromise<Array<EtAccountResponse>> {
            return localVarFp.listEtAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem delete report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtDeleteReports(facility: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtDeleteReportResponse>> {
            return localVarFp.listEtDeleteReports(facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtDocumentTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<EtCpDocumentTypeResponse>> {
            return localVarFp.listEtDocumentTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem facility tax list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtFacilityTaxes(facility: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtFacilityTaxResponse>> {
            return localVarFp.listEtFacilityTaxes(facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem monthly report list
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtMonthlyReports(facility: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtMonthlyReportResponse>> {
            return localVarFp.listEtMonthlyReports(facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report facility statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtReportFacilityStatuses(options?: RawAxiosRequestConfig): AxiosPromise<Array<EtCpReportFacilityStatusResponse>> {
            return localVarFp.listEtReportFacilityStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tax payment types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtTaxPaymentTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<EtCpTaxPaymentTypeResponse>> {
            return localVarFp.listEtTaxPaymentTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {Array<number>} tourists 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listEtTouristsTaxes(tourists: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtTouristTaxResponse>> {
            return localVarFp.listEtTouristsTaxes(tourists, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eTurizem taxes for selected tourists
         * @param {EtTaxesRequest} etTaxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEtTouristsTaxes1(etTaxesRequest: EtTaxesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EtTouristTaxResponse>> {
            return localVarFp.listEtTouristsTaxes1(etTaxesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync eTurizem account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEtAccount(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.syncEtAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update eTurizem facility tax
         * @param {number} id 
         * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtFacilityTax(id: number, etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig): AxiosPromise<EtFacilityTaxResponse> {
            return localVarFp.updateEtFacilityTax(id, etFacilityTaxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update eTurizem monthly report and send it to authority
         * @param {number} id 
         * @param {EtMonthlyReportRequest} etMonthlyReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtMonthlyReport(id: number, etMonthlyReportRequest: EtMonthlyReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<EtMonthlyReportResponse> {
            return localVarFp.updateEtMonthlyReport(id, etMonthlyReportRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ETurizemApi - object-oriented interface
 * @export
 * @class ETurizemApi
 * @extends {BaseAPI}
 */
export class ETurizemApi extends BaseAPI {
    /**
     * 
     * @summary Add eTurizem account
     * @param {EtAccountRequest} etAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public addEtAccount(etAccountRequest: EtAccountRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).addEtAccount(etAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change eTurizem account\'s password
     * @param {number} id 
     * @param {EtAccountPassChangeRequest} etAccountPassChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public changeEtAccountPass(id: number, etAccountPassChangeRequest: EtAccountPassChangeRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).changeEtAccountPass(id, etAccountPassChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create eTurizem facility tax
     * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public createEtFacilityTax(etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).createEtFacilityTax(etFacilityTaxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer\'s eVisitor account
     * @param {number} customerId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public deleteCustomerEtAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).deleteCustomerEtAccounts(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete eTurizem account
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public deleteEtAccount(id: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).deleteEtAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete eTurizem facility tax
     * @param {number} id 
     * @param {number} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public deleteEtFacilityTax(id: number, facility: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).deleteEtFacilityTax(id, facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export eTurizem tourists to PDF
     * @param {number} facility 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public exportEtTourists(facility: number, from: string, to: string, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).exportEtTourists(facility, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem tax report
     * @param {number} facility 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public getEtTaxReport(facility: number, from: string, to: string, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).getEtTaxReport(facility, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer\'s eturizem accounts
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listCustomerEtAccounts(id: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listCustomerEtAccounts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem account list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtAccounts(options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem delete report list
     * @param {number} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtDeleteReports(facility: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtDeleteReports(facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtDocumentTypes(options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtDocumentTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem facility tax list
     * @param {number} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtFacilityTaxes(facility: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtFacilityTaxes(facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem monthly report list
     * @param {number} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtMonthlyReports(facility: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtMonthlyReports(facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report facility statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtReportFacilityStatuses(options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtReportFacilityStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tax payment types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtTaxPaymentTypes(options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtTaxPaymentTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem taxes for selected tourists
     * @param {Array<number>} tourists 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtTouristsTaxes(tourists: Array<number>, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtTouristsTaxes(tourists, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eTurizem taxes for selected tourists
     * @param {EtTaxesRequest} etTaxesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public listEtTouristsTaxes1(etTaxesRequest: EtTaxesRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).listEtTouristsTaxes1(etTaxesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync eTurizem account
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public syncEtAccount(id: number, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).syncEtAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update eTurizem facility tax
     * @param {number} id 
     * @param {EtFacilityTaxRequest} etFacilityTaxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public updateEtFacilityTax(id: number, etFacilityTaxRequest: EtFacilityTaxRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).updateEtFacilityTax(id, etFacilityTaxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update eTurizem monthly report and send it to authority
     * @param {number} id 
     * @param {EtMonthlyReportRequest} etMonthlyReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ETurizemApi
     */
    public updateEtMonthlyReport(id: number, etMonthlyReportRequest: EtMonthlyReportRequest, options?: RawAxiosRequestConfig) {
        return ETurizemApiFp(this.configuration).updateEtMonthlyReport(id, etMonthlyReportRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EVisitorApi - axios parameter creator
 * @export
 */
export const EVisitorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add eVisitor account
         * @param {EvAccountRequest} evAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvAccount: async (evAccountRequest: EvAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evAccountRequest' is not null or undefined
            assertParamExists('addEvAccount', 'evAccountRequest', evAccountRequest)
            const localVarPath = `/protected/evisitors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change eVisitor account\'s password
         * @param {number} id 
         * @param {EvAccountPassChangeRequest} evAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEvAccountPass: async (id: number, evAccountPassChangeRequest: EvAccountPassChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeEvAccountPass', 'id', id)
            // verify required parameter 'evAccountPassChangeRequest' is not null or undefined
            assertParamExists('changeEvAccountPass', 'evAccountPassChangeRequest', evAccountPassChangeRequest)
            const localVarPath = `/protected/evisitors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evAccountPassChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerEvAccounts: async (customerId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomerEvAccounts', 'customerId', customerId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerEvAccounts', 'id', id)
            const localVarPath = `/private/customers/{customerId}/evisitors/{id}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEvAccount', 'id', id)
            const localVarPath = `/protected/evisitors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer\'s eVisitor accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerEvAccounts: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listCustomerEvAccounts', 'id', id)
            const localVarPath = `/private/customers/{id}/evisitors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get eVisitor account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/evisitors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get arrival organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvArrivalOrganisations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/evisitor/arrival/organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvDocumentTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/evisitor/document/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvOfferedServices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/evisitor/offered/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment categories for facility
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvPaymentCategories: async (facility: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facility' is not null or undefined
            assertParamExists('listEvPaymentCategories', 'facility', facility)
            const localVarPath = `/protected/evisitor/payment/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settlements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvSettlements: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/evisitor/settlements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEvAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncEvAccount', 'id', id)
            const localVarPath = `/protected/evisitors/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EVisitorApi - functional programming interface
 * @export
 */
export const EVisitorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EVisitorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add eVisitor account
         * @param {EvAccountRequest} evAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEvAccount(evAccountRequest: EvAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEvAccount(evAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.addEvAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change eVisitor account\'s password
         * @param {number} id 
         * @param {EvAccountPassChangeRequest} evAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEvAccountPass(id: number, evAccountPassChangeRequest: EvAccountPassChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEvAccountPass(id, evAccountPassChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.changeEvAccountPass']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerEvAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerEvAccounts(customerId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.deleteCustomerEvAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.deleteEvAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer\'s eVisitor accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerEvAccounts(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerEvAccounts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listCustomerEvAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get eVisitor account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get arrival organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvArrivalOrganisations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvCpArrivalOrganisationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvArrivalOrganisations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvArrivalOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvDocumentTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvCpDocumentTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvDocumentTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvDocumentTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get offered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvOfferedServices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvCpOfferedServiceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvOfferedServices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvOfferedServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get payment categories for facility
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvPaymentCategories(facility: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvCpPaymentCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvPaymentCategories(facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvPaymentCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get settlements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvSettlements(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvCpSettlementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvSettlements(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.listEvSettlements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncEvAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncEvAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EVisitorApi.syncEvAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EVisitorApi - factory interface
 * @export
 */
export const EVisitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EVisitorApiFp(configuration)
    return {
        /**
         * 
         * @summary Add eVisitor account
         * @param {EvAccountRequest} evAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvAccount(evAccountRequest: EvAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<EvAccountResponse> {
            return localVarFp.addEvAccount(evAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change eVisitor account\'s password
         * @param {number} id 
         * @param {EvAccountPassChangeRequest} evAccountPassChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEvAccountPass(id: number, evAccountPassChangeRequest: EvAccountPassChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.changeEvAccountPass(id, evAccountPassChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer\'s eVisitor account
         * @param {number} customerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerEvAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteCustomerEvAccounts(customerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvAccount(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteEvAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer\'s eVisitor accounts
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerEvAccounts(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EvAccountResponse>> {
            return localVarFp.listCustomerEvAccounts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get eVisitor account list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvAccounts(options?: RawAxiosRequestConfig): AxiosPromise<Array<EvAccountResponse>> {
            return localVarFp.listEvAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get arrival organisations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvArrivalOrganisations(options?: RawAxiosRequestConfig): AxiosPromise<Array<EvCpArrivalOrganisationResponse>> {
            return localVarFp.listEvArrivalOrganisations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvDocumentTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<EvCpDocumentTypeResponse>> {
            return localVarFp.listEvDocumentTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvOfferedServices(options?: RawAxiosRequestConfig): AxiosPromise<Array<EvCpOfferedServiceResponse>> {
            return localVarFp.listEvOfferedServices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment categories for facility
         * @param {number} facility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvPaymentCategories(facility: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EvCpPaymentCategoryResponse>> {
            return localVarFp.listEvPaymentCategories(facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settlements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvSettlements(options?: RawAxiosRequestConfig): AxiosPromise<Array<EvCpSettlementResponse>> {
            return localVarFp.listEvSettlements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync eVisitor account
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEvAccount(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.syncEvAccount(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EVisitorApi - object-oriented interface
 * @export
 * @class EVisitorApi
 * @extends {BaseAPI}
 */
export class EVisitorApi extends BaseAPI {
    /**
     * 
     * @summary Add eVisitor account
     * @param {EvAccountRequest} evAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public addEvAccount(evAccountRequest: EvAccountRequest, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).addEvAccount(evAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change eVisitor account\'s password
     * @param {number} id 
     * @param {EvAccountPassChangeRequest} evAccountPassChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public changeEvAccountPass(id: number, evAccountPassChangeRequest: EvAccountPassChangeRequest, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).changeEvAccountPass(id, evAccountPassChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer\'s eVisitor account
     * @param {number} customerId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public deleteCustomerEvAccounts(customerId: number, id: number, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).deleteCustomerEvAccounts(customerId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete eVisitor account
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public deleteEvAccount(id: number, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).deleteEvAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer\'s eVisitor accounts
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listCustomerEvAccounts(id: number, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listCustomerEvAccounts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get eVisitor account list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvAccounts(options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get arrival organisations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvArrivalOrganisations(options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvArrivalOrganisations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvDocumentTypes(options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvDocumentTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offered services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvOfferedServices(options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvOfferedServices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment categories for facility
     * @param {number} facility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvPaymentCategories(facility: number, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvPaymentCategories(facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settlements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public listEvSettlements(options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).listEvSettlements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync eVisitor account
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EVisitorApi
     */
    public syncEvAccount(id: number, options?: RawAxiosRequestConfig) {
        return EVisitorApiFp(this.configuration).syncEvAccount(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacility: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFacility', 'id', id)
            const localVarPath = `/protected/facilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFacility: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findFacility', 'id', id)
            const localVarPath = `/protected/facilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facility list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacilities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update facility
         * @param {number} id 
         * @param {FacilityRequest} facilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacility: async (id: number, facilityRequest: FacilityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFacility', 'id', id)
            // verify required parameter 'facilityRequest' is not null or undefined
            assertParamExists('updateFacility', 'facilityRequest', facilityRequest)
            const localVarPath = `/protected/facilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFacility(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFacility(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FacilityApi.deleteFacility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFacility(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFacility(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FacilityApi.findFacility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get facility list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFacilities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFacilities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FacilityApi.listFacilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update facility
         * @param {number} id 
         * @param {FacilityRequest} facilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFacility(id: number, facilityRequest: FacilityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFacility(id, facilityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FacilityApi.updateFacility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacility(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteFacility(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facility
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFacility(id: number, options?: RawAxiosRequestConfig): AxiosPromise<FacilityResponse> {
            return localVarFp.findFacility(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facility list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacilities(options?: RawAxiosRequestConfig): AxiosPromise<Array<FacilityResponse>> {
            return localVarFp.listFacilities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update facility
         * @param {number} id 
         * @param {FacilityRequest} facilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacility(id: number, facilityRequest: FacilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<FacilityResponse> {
            return localVarFp.updateFacility(id, facilityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
    /**
     * 
     * @summary Delete facility
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public deleteFacility(id: number, options?: RawAxiosRequestConfig) {
        return FacilityApiFp(this.configuration).deleteFacility(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facility
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public findFacility(id: number, options?: RawAxiosRequestConfig) {
        return FacilityApiFp(this.configuration).findFacility(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facility list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public listFacilities(options?: RawAxiosRequestConfig) {
        return FacilityApiFp(this.configuration).listFacilities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update facility
     * @param {number} id 
     * @param {FacilityRequest} facilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public updateFacility(id: number, facilityRequest: FacilityRequest, options?: RawAxiosRequestConfig) {
        return FacilityApiFp(this.configuration).updateFacility(id, facilityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoice: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelInvoice', 'id', id)
            const localVarPath = `/protected/invoices/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create invoice or save draft
         * @param {InvoiceRequest} invoiceRequest 
         * @param {boolean} [draft] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: async (invoiceRequest: InvoiceRequest, draft?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceRequest' is not null or undefined
            assertParamExists('createInvoice', 'invoiceRequest', invoiceRequest)
            const localVarPath = `/protected/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (draft !== undefined) {
                localVarQueryParameter['draft'] = draft;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete invoice draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDraft', 'id', id)
            const localVarPath = `/protected/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export invoices
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoices: async (from: string, to: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportInvoices', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportInvoices', 'to', to)
            const localVarPath = `/protected/invoices/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice details (from invoicing service)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoice: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findInvoice', 'id', id)
            const localVarPath = `/protected/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice PDF
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePdf: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvoicePdf', 'id', id)
            const localVarPath = `/protected/invoices/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvoices: async (page?: number, size?: number, sort?: Array<string>, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send invoice to mail
         * @param {string} id 
         * @param {InvoiceSendRequest} invoiceSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvoice: async (id: string, invoiceSendRequest: InvoiceSendRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInvoice', 'id', id)
            // verify required parameter 'invoiceSendRequest' is not null or undefined
            assertParamExists('sendInvoice', 'invoiceSendRequest', invoiceSendRequest)
            const localVarPath = `/protected/invoices/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceSendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvoice(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoice(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.cancelInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create invoice or save draft
         * @param {InvoiceRequest} invoiceRequest 
         * @param {boolean} [draft] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoice(invoiceRequest: InvoiceRequest, draft?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoice(invoiceRequest, draft, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.createInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete invoice draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDraft(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDraft(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.deleteDraft']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export invoices
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportInvoices(from: string, to: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportInvoicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportInvoices(from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.exportInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get invoice details (from invoicing service)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findInvoice(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findInvoice(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.findInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get invoice PDF
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicePdf(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicePdf(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.getInvoicePdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get invoice list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvoices(page?: number, size?: number, sort?: Array<string>, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SliceInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvoices(page, size, sort, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.listInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send invoice to mail
         * @param {string} id 
         * @param {InvoiceSendRequest} invoiceSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvoice(id: string, invoiceSendRequest: InvoiceSendRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvoice(id, invoiceSendRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.sendInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoice(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.cancelInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create invoice or save draft
         * @param {InvoiceRequest} invoiceRequest 
         * @param {boolean} [draft] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice(invoiceRequest: InvoiceRequest, draft?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<InvoiceResponse> {
            return localVarFp.createInvoice(invoiceRequest, draft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete invoice draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export invoices
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoices(from: string, to: string, options?: RawAxiosRequestConfig): AxiosPromise<ExportInvoicesResponse> {
            return localVarFp.exportInvoices(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice details (from invoicing service)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInvoice(id: string, options?: RawAxiosRequestConfig): AxiosPromise<InvoiceResponse> {
            return localVarFp.findInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice PDF
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePdf(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getInvoicePdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvoices(page?: number, size?: number, sort?: Array<string>, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<SliceInvoiceListResponse> {
            return localVarFp.listInvoices(page, size, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send invoice to mail
         * @param {string} id 
         * @param {InvoiceSendRequest} invoiceSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvoice(id: string, invoiceSendRequest: InvoiceSendRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.sendInvoice(id, invoiceSendRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Cancel invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public cancelInvoice(id: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).cancelInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create invoice or save draft
     * @param {InvoiceRequest} invoiceRequest 
     * @param {boolean} [draft] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public createInvoice(invoiceRequest: InvoiceRequest, draft?: boolean, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).createInvoice(invoiceRequest, draft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete invoice draft
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public deleteDraft(id: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).deleteDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export invoices
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public exportInvoices(from: string, to: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).exportInvoices(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice details (from invoicing service)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public findInvoice(id: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).findInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice PDF
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getInvoicePdf(id: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getInvoicePdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice list (paginated)
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public listInvoices(page?: number, size?: number, sort?: Array<string>, search?: string, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).listInvoices(page, size, sort, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send invoice to mail
     * @param {string} id 
     * @param {InvoiceSendRequest} invoiceSendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public sendInvoice(id: string, invoiceSendRequest: InvoiceSendRequest, options?: RawAxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).sendInvoice(id, invoiceSendRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LicenceApi - axios parameter creator
 * @export
 */
export const LicenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create licences for referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicencesForReferrals: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/licences/referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new licences subscription
         * @param {SubscriptionRequest} subscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (subscriptionRequest: SubscriptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionRequest' is not null or undefined
            assertParamExists('createSubscription', 'subscriptionRequest', subscriptionRequest)
            const localVarPath = `/protected/licences/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription coupon info
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponInfo: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getCouponInfo', 'code', code)
            const localVarPath = `/protected/licences/subscriptions/coupons/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get new subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/licences/subscriptions/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer licences
         * @param {boolean} [valid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLicences: async (valid?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/licences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (valid !== undefined) {
                localVarQueryParameter['valid'] = valid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List customer subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/licences/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenceApi - functional programming interface
 * @export
 */
export const LicenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create licences for referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLicencesForReferrals(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLicencesForReferrals(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.createLicencesForReferrals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create new licences subscription
         * @param {SubscriptionRequest} subscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(subscriptionRequest: SubscriptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(subscriptionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.createSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get subscription coupon info
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponInfo(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponInfo(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.getCouponInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get new subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicencePaymentInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.getSubscriptionInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer licences
         * @param {boolean} [valid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLicences(valid?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLicences(valid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.listLicences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List customer subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubscriptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubscriptions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicenceApi.listSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LicenceApi - factory interface
 * @export
 */
export const LicenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create licences for referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicencesForReferrals(options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.createLicencesForReferrals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new licences subscription
         * @param {SubscriptionRequest} subscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(subscriptionRequest: SubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionResponse> {
            return localVarFp.createSubscription(subscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription coupon info
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponInfo(code: string, options?: RawAxiosRequestConfig): AxiosPromise<CouponInfoResponse> {
            return localVarFp.getCouponInfo(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get new subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo(options?: RawAxiosRequestConfig): AxiosPromise<Array<LicencePaymentInfoResponse>> {
            return localVarFp.getSubscriptionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer licences
         * @param {boolean} [valid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLicences(valid?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<LicenceResponse>> {
            return localVarFp.listLicences(valid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List customer subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptions(options?: RawAxiosRequestConfig): AxiosPromise<Array<SubscriptionResponse>> {
            return localVarFp.listSubscriptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenceApi - object-oriented interface
 * @export
 * @class LicenceApi
 * @extends {BaseAPI}
 */
export class LicenceApi extends BaseAPI {
    /**
     * 
     * @summary Create licences for referrals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public createLicencesForReferrals(options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).createLicencesForReferrals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new licences subscription
     * @param {SubscriptionRequest} subscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public createSubscription(subscriptionRequest: SubscriptionRequest, options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).createSubscription(subscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscription coupon info
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public getCouponInfo(code: string, options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).getCouponInfo(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get new subscription info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public getSubscriptionInfo(options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).getSubscriptionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer licences
     * @param {boolean} [valid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public listLicences(valid?: boolean, options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).listLicences(valid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List customer subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public listSubscriptions(options?: RawAxiosRequestConfig) {
        return LicenceApiFp(this.configuration).listSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notification list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set notification to read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotificationToRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setNotificationToRead', 'id', id)
            const localVarPath = `/protected/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set notifications to read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotificationsToRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/notifications/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notification list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SliceNotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotifications(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.listNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set notification to read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNotificationToRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNotificationToRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.setNotificationToRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set notifications to read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNotificationsToRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNotificationsToRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.setNotificationsToRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notification list (paginated)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SliceNotificationResponse> {
            return localVarFp.listNotifications(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set notification to read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotificationToRead(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.setNotificationToRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set notifications to read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNotificationsToRead(options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.setNotificationsToRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Get notification list (paginated)
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public listNotifications(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).listNotifications(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set notification to read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setNotificationToRead(id: number, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setNotificationToRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set notifications to read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public setNotificationsToRead(options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).setNotificationsToRead(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get referrals info for free trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/referrals/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get referrals info for free trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralsInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralsInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferralApi.getReferralsInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiFp(configuration)
    return {
        /**
         * 
         * @summary Get referrals info for free trial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralsInfo(options?: RawAxiosRequestConfig): AxiosPromise<ReferralInfoResponse> {
            return localVarFp.getReferralsInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
    /**
     * 
     * @summary Get referrals info for free trial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getReferralsInfo(options?: RawAxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getReferralsInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReservationApi - axios parameter creator
 * @export
 */
export const ReservationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPart: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelPart', 'id', id)
            const localVarPath = `/protected/reservations/parts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create reservation with parts and tourists (check-in)
         * @param {ReservationRequest} reservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (reservationRequest: ReservationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationRequest' is not null or undefined
            assertParamExists('create', 'reservationRequest', reservationRequest)
            const localVarPath = `/protected/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create self check-in link. Link can be send to tourist mail.
         * @param {number} id 
         * @param {CheckInLinkRequest} checkInLinkRequest 
         * @param {boolean} [send] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckInLink: async (id: number, checkInLinkRequest: CheckInLinkRequest, send?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createCheckInLink', 'id', id)
            // verify required parameter 'checkInLinkRequest' is not null or undefined
            assertParamExists('createCheckInLink', 'checkInLinkRequest', checkInLinkRequest)
            const localVarPath = `/protected/reservations/parts/{id}/checkin/link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (send !== undefined) {
                localVarQueryParameter['send'] = send;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get full data for reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPart: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPart', 'id', id)
            const localVarPath = `/protected/reservations/parts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public data for reservation part
         * @param {number} id 
         * @param {number} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPart: async (id: number, customer: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPublicPart', 'id', id)
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('findPublicPart', 'customer', customer)
            const localVarPath = `/public/reservations/parts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get full data reservation parts list
         * @param {string} from 
         * @param {string} to 
         * @param {number} [facility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParts: async (from: string, to: string, facility?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('listParts', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('listParts', 'to', to)
            const localVarPath = `/protected/reservations/parts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reservation parts tourists
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartsTourists: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listPartsTourists', 'id', id)
            const localVarPath = `/protected/reservations/parts/{id}/tourists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reservation invoice list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservationInvoices: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listReservationInvoices', 'id', id)
            const localVarPath = `/protected/reservations/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move tourist to another room.
         * @param {number} id 
         * @param {MoveTouristsRequest} moveTouristsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTourist: async (id: number, moveTouristsRequest: MoveTouristsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveTourist', 'id', id)
            // verify required parameter 'moveTouristsRequest' is not null or undefined
            assertParamExists('moveTourist', 'moveTouristsRequest', moveTouristsRequest)
            const localVarPath = `/protected/reservations/parts/{id}/tourists/move`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveTouristsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update reservation
         * @param {number} id 
         * @param {UpdateReservationRequest} updateReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateReservationRequest: UpdateReservationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateReservationRequest' is not null or undefined
            assertParamExists('update', 'updateReservationRequest', updateReservationRequest)
            const localVarPath = `/protected/reservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update reservation part
         * @param {number} id 
         * @param {ReservationPartRequest} reservationPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePart: async (id: number, reservationPartRequest: ReservationPartRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePart', 'id', id)
            // verify required parameter 'reservationPartRequest' is not null or undefined
            assertParamExists('updatePart', 'reservationPartRequest', reservationPartRequest)
            const localVarPath = `/protected/reservations/parts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationPartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationApi - functional programming interface
 * @export
 */
export const ReservationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPart(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPart(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.cancelPart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create reservation with parts and tourists (check-in)
         * @param {ReservationRequest} reservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(reservationRequest: ReservationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(reservationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create self check-in link. Link can be send to tourist mail.
         * @param {number} id 
         * @param {CheckInLinkRequest} checkInLinkRequest 
         * @param {boolean} [send] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckInLink(id: number, checkInLinkRequest: CheckInLinkRequest, send?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckInLink(id, checkInLinkRequest, send, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.createCheckInLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get full data for reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPart(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullReservationPartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPart(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.findPart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get public data for reservation part
         * @param {number} id 
         * @param {number} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicPart(id: number, customer: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicReservationPartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicPart(id, customer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.findPublicPart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get full data reservation parts list
         * @param {string} from 
         * @param {string} to 
         * @param {number} [facility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listParts(from: string, to: string, facility?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullReservationPartResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listParts(from, to, facility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.listParts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get reservation parts tourists
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPartsTourists(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TouristResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPartsTourists(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.listPartsTourists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get reservation invoice list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReservationInvoices(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReservationInvoices(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.listReservationInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Move tourist to another room.
         * @param {number} id 
         * @param {MoveTouristsRequest} moveTouristsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveTourist(id: number, moveTouristsRequest: MoveTouristsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationPartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveTourist(id, moveTouristsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.moveTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update reservation
         * @param {number} id 
         * @param {UpdateReservationRequest} updateReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateReservationRequest: UpdateReservationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateReservationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update reservation part
         * @param {number} id 
         * @param {ReservationPartRequest} reservationPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePart(id: number, reservationPartRequest: ReservationPartRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationPartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePart(id, reservationPartRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationApi.updatePart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReservationApi - factory interface
 * @export
 */
export const ReservationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPart(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.cancelPart(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create reservation with parts and tourists (check-in)
         * @param {ReservationRequest} reservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(reservationRequest: ReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReservationResponse> {
            return localVarFp.create(reservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create self check-in link. Link can be send to tourist mail.
         * @param {number} id 
         * @param {CheckInLinkRequest} checkInLinkRequest 
         * @param {boolean} [send] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckInLink(id: number, checkInLinkRequest: CheckInLinkRequest, send?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<CheckInLinkResponse> {
            return localVarFp.createCheckInLink(id, checkInLinkRequest, send, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get full data for reservation part
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPart(id: number, options?: RawAxiosRequestConfig): AxiosPromise<FullReservationPartResponse> {
            return localVarFp.findPart(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public data for reservation part
         * @param {number} id 
         * @param {number} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPart(id: number, customer: number, options?: RawAxiosRequestConfig): AxiosPromise<PublicReservationPartResponse> {
            return localVarFp.findPublicPart(id, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get full data reservation parts list
         * @param {string} from 
         * @param {string} to 
         * @param {number} [facility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParts(from: string, to: string, facility?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<FullReservationPartResponse>> {
            return localVarFp.listParts(from, to, facility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reservation parts tourists
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartsTourists(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TouristResponse>> {
            return localVarFp.listPartsTourists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reservation invoice list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservationInvoices(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<InvoiceListResponse>> {
            return localVarFp.listReservationInvoices(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move tourist to another room.
         * @param {number} id 
         * @param {MoveTouristsRequest} moveTouristsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTourist(id: number, moveTouristsRequest: MoveTouristsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReservationPartResponse> {
            return localVarFp.moveTourist(id, moveTouristsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update reservation
         * @param {number} id 
         * @param {UpdateReservationRequest} updateReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateReservationRequest: UpdateReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReservationResponse> {
            return localVarFp.update(id, updateReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update reservation part
         * @param {number} id 
         * @param {ReservationPartRequest} reservationPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePart(id: number, reservationPartRequest: ReservationPartRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReservationPartResponse> {
            return localVarFp.updatePart(id, reservationPartRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
export class ReservationApi extends BaseAPI {
    /**
     * 
     * @summary Cancel reservation part
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public cancelPart(id: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).cancelPart(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create reservation with parts and tourists (check-in)
     * @param {ReservationRequest} reservationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public create(reservationRequest: ReservationRequest, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).create(reservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create self check-in link. Link can be send to tourist mail.
     * @param {number} id 
     * @param {CheckInLinkRequest} checkInLinkRequest 
     * @param {boolean} [send] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public createCheckInLink(id: number, checkInLinkRequest: CheckInLinkRequest, send?: boolean, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).createCheckInLink(id, checkInLinkRequest, send, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get full data for reservation part
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public findPart(id: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).findPart(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public data for reservation part
     * @param {number} id 
     * @param {number} customer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public findPublicPart(id: number, customer: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).findPublicPart(id, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get full data reservation parts list
     * @param {string} from 
     * @param {string} to 
     * @param {number} [facility] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public listParts(from: string, to: string, facility?: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).listParts(from, to, facility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reservation parts tourists
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public listPartsTourists(id: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).listPartsTourists(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reservation invoice list
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public listReservationInvoices(id: number, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).listReservationInvoices(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move tourist to another room.
     * @param {number} id 
     * @param {MoveTouristsRequest} moveTouristsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public moveTourist(id: number, moveTouristsRequest: MoveTouristsRequest, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).moveTourist(id, moveTouristsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update reservation
     * @param {number} id 
     * @param {UpdateReservationRequest} updateReservationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public update(id: number, updateReservationRequest: UpdateReservationRequest, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).update(id, updateReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update reservation part
     * @param {number} id 
     * @param {ReservationPartRequest} reservationPartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public updatePart(id: number, reservationPartRequest: ReservationPartRequest, options?: RawAxiosRequestConfig) {
        return ReservationApiFp(this.configuration).updatePart(id, reservationPartRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create room
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom: async (roomRequest: RoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomRequest' is not null or undefined
            assertParamExists('createRoom', 'roomRequest', roomRequest)
            const localVarPath = `/protected/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoom', 'id', id)
            const localVarPath = `/protected/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get room list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRooms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update room
         * @param {number} id 
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom: async (id: number, roomRequest: RoomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoom', 'id', id)
            // verify required parameter 'roomRequest' is not null or undefined
            assertParamExists('updateRoom', 'roomRequest', roomRequest)
            const localVarPath = `/protected/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create room
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoom(roomRequest: RoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoom(roomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomApi.createRoom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoom(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoom(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomApi.deleteRoom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get room list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRooms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRooms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomApi.listRooms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update room
         * @param {number} id 
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoom(id: number, roomRequest: RoomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoom(id, roomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomApi.updateRoom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @summary Create room
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom(roomRequest: RoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.createRoom(roomRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get room list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRooms(options?: RawAxiosRequestConfig): AxiosPromise<Array<RoomResponse>> {
            return localVarFp.listRooms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update room
         * @param {number} id 
         * @param {RoomRequest} roomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom(id: number, roomRequest: RoomRequest, options?: RawAxiosRequestConfig): AxiosPromise<RoomResponse> {
            return localVarFp.updateRoom(id, roomRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @summary Create room
     * @param {RoomRequest} roomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public createRoom(roomRequest: RoomRequest, options?: RawAxiosRequestConfig) {
        return RoomApiFp(this.configuration).createRoom(roomRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete room
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public deleteRoom(id: number, options?: RawAxiosRequestConfig) {
        return RoomApiFp(this.configuration).deleteRoom(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get room list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public listRooms(options?: RawAxiosRequestConfig) {
        return RoomApiFp(this.configuration).listRooms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update room
     * @param {number} id 
     * @param {RoomRequest} roomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public updateRoom(id: number, roomRequest: RoomRequest, options?: RawAxiosRequestConfig) {
        return RoomApiFp(this.configuration).updateRoom(id, roomRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List customer settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/settings/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSetting: async (id: number, settingRequest: SettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerSetting', 'id', id)
            // verify required parameter 'settingRequest' is not null or undefined
            assertParamExists('updateCustomerSetting', 'settingRequest', settingRequest)
            const localVarPath = `/protected/settings/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSetting: async (id: number, settingRequest: SettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserSetting', 'id', id)
            // verify required parameter 'settingRequest' is not null or undefined
            assertParamExists('updateUserSetting', 'settingRequest', settingRequest)
            const localVarPath = `/protected/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List customer settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerSettingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingApi.listCustomerSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingApi.listUserSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update customer setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerSetting(id, settingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingApi.updateCustomerSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update user setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserSetting(id, settingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingApi.updateUserSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @summary List customer settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerSettings(options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerSettingResponse>> {
            return localVarFp.listCustomerSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSettings(options?: RawAxiosRequestConfig): AxiosPromise<Array<SettingResponse>> {
            return localVarFp.listUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerSettingResponse> {
            return localVarFp.updateCustomerSetting(id, settingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user setting
         * @param {number} id 
         * @param {SettingRequest} settingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<SettingResponse> {
            return localVarFp.updateUserSetting(id, settingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @summary List customer settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public listCustomerSettings(options?: RawAxiosRequestConfig) {
        return SettingApiFp(this.configuration).listCustomerSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List user settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public listUserSettings(options?: RawAxiosRequestConfig) {
        return SettingApiFp(this.configuration).listUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer setting
     * @param {number} id 
     * @param {SettingRequest} settingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public updateCustomerSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig) {
        return SettingApiFp(this.configuration).updateCustomerSetting(id, settingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user setting
     * @param {number} id 
     * @param {SettingRequest} settingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public updateUserSetting(id: number, settingRequest: SettingRequest, options?: RawAxiosRequestConfig) {
        return SettingApiFp(this.configuration).updateUserSetting(id, settingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSubscription', 'id', id)
            const localVarPath = `/protected/stripe/subscription/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhook: async (stripeSignature: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('webhook', 'stripeSignature', stripeSignature)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webhook', 'body', body)
            const localVarPath = `/public/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.cancelSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhook(stripeSignature: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhook(stripeSignature, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.webhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Webhook for Stripe events
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhook(stripeSignature: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.webhook(stripeSignature, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @summary Webhook for Stripe events
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public cancelSubscription(id: string, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).cancelSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Webhook for Stripe events
     * @param {string} stripeSignature 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public webhook(stripeSignature: string, body: string, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).webhook(stripeSignature, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TouristApi - axios parameter creator
 * @export
 */
export const TouristApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel tourist check-in
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCheckInTourist: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelCheckInTourist', 'id', id)
            const localVarPath = `/protected/tourists/checkin/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check-in tourist
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInTourist: async (checkInPartRequest: CheckInPartRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkInPartRequest' is not null or undefined
            assertParamExists('checkInTourist', 'checkInPartRequest', checkInPartRequest)
            const localVarPath = `/protected/tourists/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInPartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check-out tourist
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOutTourist: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkOutTourist', 'id', id)
            const localVarPath = `/protected/tourists/checkout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tourist details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTourist: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTourist', 'id', id)
            const localVarPath = `/protected/tourists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tourist\'s event list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTouristEvents: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listTouristEvents', 'id', id)
            const localVarPath = `/protected/tourists/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tourist list
         * @param {ListTouristsStateEnum} state 
         * @param {number} [facility] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTourists: async (state: ListTouristsStateEnum, facility?: number, from?: string, to?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('listTourists', 'state', state)
            const localVarPath = `/protected/tourists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (facility !== undefined) {
                localVarQueryParameter['facility'] = facility;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Self check-in tourist
         * @param {number} customer 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfCheckInTourist: async (customer: number, checkInPartRequest: CheckInPartRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('selfCheckInTourist', 'customer', customer)
            // verify required parameter 'checkInPartRequest' is not null or undefined
            assertParamExists('selfCheckInTourist', 'checkInPartRequest', checkInPartRequest)
            const localVarPath = `/public/tourists/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInPartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update tourist check-in
         * @param {number} id 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCheckInTourist: async (id: number, checkInPartRequest: CheckInPartRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCheckInTourist', 'id', id)
            // verify required parameter 'checkInPartRequest' is not null or undefined
            assertParamExists('updateCheckInTourist', 'checkInPartRequest', checkInPartRequest)
            const localVarPath = `/protected/tourists/checkin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInPartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TouristApi - functional programming interface
 * @export
 */
export const TouristApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TouristApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel tourist check-in
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelCheckInTourist(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelCheckInTourist(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.cancelCheckInTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check-in tourist
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInTourist(checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TouristResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInTourist(checkInPartRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.checkInTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check-out tourist
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkOutTourist(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkOutTourist(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.checkOutTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get tourist details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTourist(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TouristResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTourist(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.findTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get tourist\'s event list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTouristEvents(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TouristEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTouristEvents(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.listTouristEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get tourist list
         * @param {ListTouristsStateEnum} state 
         * @param {number} [facility] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTourists(state: ListTouristsStateEnum, facility?: number, from?: string, to?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TouristResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTourists(state, facility, from, to, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.listTourists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Self check-in tourist
         * @param {number} customer 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selfCheckInTourist(customer: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selfCheckInTourist(customer, checkInPartRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.selfCheckInTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update tourist check-in
         * @param {number} id 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCheckInTourist(id: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TouristResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCheckInTourist(id, checkInPartRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TouristApi.updateCheckInTourist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TouristApi - factory interface
 * @export
 */
export const TouristApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TouristApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel tourist check-in
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCheckInTourist(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.cancelCheckInTourist(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check-in tourist
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInTourist(checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): AxiosPromise<TouristResponse> {
            return localVarFp.checkInTourist(checkInPartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check-out tourist
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOutTourist(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.checkOutTourist(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tourist details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTourist(id: number, options?: RawAxiosRequestConfig): AxiosPromise<TouristResponse> {
            return localVarFp.findTourist(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tourist\'s event list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTouristEvents(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TouristEventResponse>> {
            return localVarFp.listTouristEvents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tourist list
         * @param {ListTouristsStateEnum} state 
         * @param {number} [facility] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTourists(state: ListTouristsStateEnum, facility?: number, from?: string, to?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TouristResponse>> {
            return localVarFp.listTourists(state, facility, from, to, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Self check-in tourist
         * @param {number} customer 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfCheckInTourist(customer: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.selfCheckInTourist(customer, checkInPartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update tourist check-in
         * @param {number} id 
         * @param {CheckInPartRequest} checkInPartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCheckInTourist(id: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig): AxiosPromise<TouristResponse> {
            return localVarFp.updateCheckInTourist(id, checkInPartRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TouristApi - object-oriented interface
 * @export
 * @class TouristApi
 * @extends {BaseAPI}
 */
export class TouristApi extends BaseAPI {
    /**
     * 
     * @summary Cancel tourist check-in
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public cancelCheckInTourist(id: number, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).cancelCheckInTourist(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check-in tourist
     * @param {CheckInPartRequest} checkInPartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public checkInTourist(checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).checkInTourist(checkInPartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check-out tourist
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public checkOutTourist(id: number, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).checkOutTourist(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tourist details
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public findTourist(id: number, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).findTourist(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tourist\'s event list
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public listTouristEvents(id: number, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).listTouristEvents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tourist list
     * @param {ListTouristsStateEnum} state 
     * @param {number} [facility] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public listTourists(state: ListTouristsStateEnum, facility?: number, from?: string, to?: string, search?: string, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).listTourists(state, facility, from, to, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Self check-in tourist
     * @param {number} customer 
     * @param {CheckInPartRequest} checkInPartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public selfCheckInTourist(customer: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).selfCheckInTourist(customer, checkInPartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update tourist check-in
     * @param {number} id 
     * @param {CheckInPartRequest} checkInPartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TouristApi
     */
    public updateCheckInTourist(id: number, checkInPartRequest: CheckInPartRequest, options?: RawAxiosRequestConfig) {
        return TouristApiFp(this.configuration).updateCheckInTourist(id, checkInPartRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListTouristsStateEnum = {
    CheckedIn: 'checked_in',
    CheckedOut: 'checked_out',
    Cancelled: 'cancelled',
    Draft: 'draft'
} as const;
export type ListTouristsStateEnum = typeof ListTouristsStateEnum[keyof typeof ListTouristsStateEnum];


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login as another user
         * @param {ImpersonateRequest} impersonateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser: async (impersonateRequest: ImpersonateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'impersonateRequest' is not null or undefined
            assertParamExists('impersonateUser', 'impersonateRequest', impersonateRequest)
            const localVarPath = `/protected/users/impersonate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impersonateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authenticated users info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Login as another user
         * @param {ImpersonateRequest} impersonateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateUser(impersonateRequest: ImpersonateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateUser(impersonateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.impersonateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get authenticated users info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.me']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe(options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.deleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login as another user
         * @param {ImpersonateRequest} impersonateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser(impersonateRequest: ImpersonateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.impersonateUser(impersonateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authenticated users info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: RawAxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Delete authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteMe(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login as another user
     * @param {ImpersonateRequest} impersonateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public impersonateUser(impersonateRequest: ImpersonateRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).impersonateUser(impersonateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authenticated users info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public me(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserAuthApi - axios parameter creator
 * @export
 */
export const UserAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login user
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/public/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send new mail token to confirm mail
         * @param {MailTokenResendRequest} mailTokenResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTokenResend: async (mailTokenResendRequest: MailTokenResendRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailTokenResendRequest' is not null or undefined
            assertParamExists('mailTokenResend', 'mailTokenResendRequest', mailTokenResendRequest)
            const localVarPath = `/public/users/mail/token/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailTokenResendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if mail token is valid and confirm mail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTokenValid: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('mailTokenValid', 'token', token)
            const localVarPath = `/public/users/mail/token/valid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {string} token 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChange: async (token: string, passwordChangeRequest: PasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('passwordChange', 'token', token)
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('passwordChange', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/public/users/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request password reset for user
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset: async (passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('passwordReset', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/public/users/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {number} [refUser] 
         * @param {number} [refCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequest: RegisterRequest, refUser?: number, refCustomer?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('register', 'registerRequest', registerRequest)
            const localVarPath = `/public/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refUser !== undefined) {
                localVarQueryParameter['ref_user'] = refUser;
            }

            if (refCustomer !== undefined) {
                localVarQueryParameter['ref_customer'] = refCustomer;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAuthApi - functional programming interface
 * @export
 */
export const UserAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login user
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send new mail token to confirm mail
         * @param {MailTokenResendRequest} mailTokenResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailTokenResend(mailTokenResendRequest: MailTokenResendRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailTokenResend(mailTokenResendRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.mailTokenResend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if mail token is valid and confirm mail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailTokenValid(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailTokenValid(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.mailTokenValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {string} token 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChange(token: string, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChange(token, passwordChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.passwordChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Request password reset for user
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordReset(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordReset(passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.passwordReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {number} [refUser] 
         * @param {number} [refCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequest: RegisterRequest, refUser?: number, refCustomer?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequest, refUser, refCustomer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAuthApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserAuthApi - factory interface
 * @export
 */
export const UserAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login user
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send new mail token to confirm mail
         * @param {MailTokenResendRequest} mailTokenResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTokenResend(mailTokenResendRequest: MailTokenResendRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.mailTokenResend(mailTokenResendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if mail token is valid and confirm mail
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTokenValid(token: string, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.mailTokenValid(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change user\'s password
         * @param {string} token 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChange(token: string, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.passwordChange(token, passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request password reset for user
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.passwordReset(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {number} [refUser] 
         * @param {number} [refCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest: RegisterRequest, refUser?: number, refCustomer?: number, options?: RawAxiosRequestConfig): AxiosPromise<StatusResponse> {
            return localVarFp.register(registerRequest, refUser, refCustomer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAuthApi - object-oriented interface
 * @export
 * @class UserAuthApi
 * @extends {BaseAPI}
 */
export class UserAuthApi extends BaseAPI {
    /**
     * 
     * @summary Login user
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send new mail token to confirm mail
     * @param {MailTokenResendRequest} mailTokenResendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public mailTokenResend(mailTokenResendRequest: MailTokenResendRequest, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).mailTokenResend(mailTokenResendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if mail token is valid and confirm mail
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public mailTokenValid(token: string, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).mailTokenValid(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change user\'s password
     * @param {string} token 
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public passwordChange(token: string, passwordChangeRequest: PasswordChangeRequest, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).passwordChange(token, passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request password reset for user
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public passwordReset(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).passwordReset(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register user
     * @param {RegisterRequest} registerRequest 
     * @param {number} [refUser] 
     * @param {number} [refCustomer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public register(registerRequest: RegisterRequest, refUser?: number, refCustomer?: number, options?: RawAxiosRequestConfig) {
        return UserAuthApiFp(this.configuration).register(registerRequest, refUser, refCustomer, options).then((request) => request(this.axios, this.basePath));
    }
}



