import i18n from 'i18next';
import { setDefaultOptions } from 'date-fns';
import {
  hr as dateHr,
  enGB as dateEn,
  sl as dateSl,
  de as dateDe,
  it as dateIt,
  fr as dateFr,
  es as dateEs,
} from 'date-fns/locale';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from './assets/i18n/en.json';
import hr from './assets/i18n/hr.json';
import sl from './assets/i18n/sl.json';
import de from './assets/i18n/de.json';
import it from './assets/i18n/it.json';
import fr from './assets/i18n/fr.json';
import es from './assets/i18n/es.json';

// Translations
const resources = {
  en: {
    translation: en,
  },
  hr: {
    translation: hr,
  },
  sl: {
    translation: sl,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    returnNull: false,
    supportedLngs: Object.keys(resources),

    detection: {
      order: ['querystring', 'navigator'],
    },

    interpolation: {
      escapeValue: false,
    },
  });

// Date locale
let locale = dateHr;

switch (i18n.language) {
  case 'en':
    locale = dateEn;
    break;
  case 'sl':
    locale = dateSl;
    break;
  case 'de':
    locale = dateDe;
    break;
  case 'it':
    locale = dateIt;
    break;
  case 'fr':
    locale = dateFr;
    break;
  case 'es':
    locale = dateEs;
    break;
}

setDefaultOptions({
  locale,
});

export default i18n;
