import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';
import { getTaxInfo } from '../serverApi/spaceInvoice';
import { StripeError } from '@stripe/stripe-js';
import MainStore from './MainStore';
import {
  CountryApi,
  CountryResponse,
  CouponInfoResponse,
  CustomerRequest,
  FacilityApi,
  FacilityResponse,
  LicenceApi,
  LicencePaymentInfoResponse,
  LicenceResponse,
  ReferralApi,
  ReferralInfoResponse,
  StripeApi,
  SubscriptionRequest,
  SubscriptionRequestPaymentMethodEnum,
  SubscriptionResponse,
} from '../serverApi/snapguest';

class BillingStore {
  mainStore: MainStore;

  // Observable
  loader = false;
  activeStep = 0;

  licences: LicenceResponse[] | null = null;
  licencePaymentInfos: LicencePaymentInfoResponse[] | null = null;
  selectedPaymentInfos: LicencePaymentInfoResponse[] = [];

  subscription: SubscriptionResponse | null = null;
  subscriptions: SubscriptionResponse[] | null = null;

  paymentMethod: SubscriptionRequestPaymentMethodEnum =
    SubscriptionRequestPaymentMethodEnum.Card;
  paymentError: StripeError | null = null;

  referralInfo: ReferralInfoResponse | null = null;

  couponInfo: CouponInfoResponse | null = null;

  facilities: FacilityResponse[] | null = null;

  countries: CountryResponse[] | null = null;

  taxNumberValid = true;

  // Other
  priceFormatter = new Intl.NumberFormat('hr-HR', {
    style: 'currency',
    currency: 'EUR',
  });

  stepperRef = React.createRef<HTMLDivElement>();

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;

    makeObservable(this, {
      loader: observable,
      activeStep: observable,
      licences: observable,
      subscription: observable,
      subscriptions: observable,
      paymentMethod: observable,
      paymentError: observable,
      licencePaymentInfos: observable,
      selectedPaymentInfos: observable,
      referralInfo: observable,
      couponInfo: observable,
      facilities: observable,
      countries: observable,
      taxNumberValid: observable,

      showLoader: action,
      hideLoader: action,
      setStep: action,
      nextStep: action,
      previousStep: action,
      setPaymentMethod: action,
      setPaymentError: action,
      validateTaxNumber: action,
      getFacilities: action,
      getLicences: action,
      getSubscriptions: action,
      getSubscriptionInfo: action,
      cancelStripeSubscription: action,
      getReferralsInfo: action,
      getCountries: action,
      selectPaymentInfos: action,
      getCouponInfo: action,

      totalPrice: computed,
      subscriptionRange: computed,
      invalidCupon: computed,
      validCupon: computed,
      couponDiscount: computed,
    });
  }

  // Actions

  validateTaxNumber(
    customer: CustomerRequest,
    callback: (valid: boolean) => void,
  ) {
    if (customer.taxSubject) {
      const taxNumber = customer.companyIdentificationNumber;
      const countryId = customer.countryId;
      if (!taxNumber || !countryId) {
        this.taxNumberValid = false;
        callback(false);
        return;
      }

      const country = this.countries?.find((c) => c.id === countryId);
      if (country) {
        this.showLoader();
        getTaxInfo(
          {
            country: country.name,
            taxNumber: taxNumber,
          },
          (taxInfo) => {
            runInAction(() => {
              if (taxInfo !== null) {
                if (
                  taxInfo?.taxClauseType === 'eu_b2b' ||
                  taxInfo?.taxClauseType === 'local'
                ) {
                  this.taxNumberValid = true;
                  callback(true);
                } else {
                  this.taxNumberValid = false;
                  callback(false);
                }
              }
              this.hideLoader();
            });
          },
        );
      }
    } else {
      this.taxNumberValid = true;
      callback(true);
    }
  }

  getFacilities() {
    this.showLoader();

    const facilityApi = new FacilityApi(this.mainStore.apiConfig);
    facilityApi
      .listFacilities()
      .then((response) => {
        runInAction(() => {
          this.facilities = response.data;
        });
      })
      .finally(() => this.hideLoader());
  }

  getLicences() {
    this.showLoader();

    const licenceApi = new LicenceApi(this.mainStore.apiConfig);
    licenceApi
      .listLicences(true)
      .then((response) => {
        runInAction(() => {
          this.licences = response.data;
        });
      })
      .finally(() => this.hideLoader());
  }

  getSubscriptions() {
    this.showLoader();

    const licenceApi = new LicenceApi(this.mainStore.apiConfig);
    licenceApi
      .listSubscriptions()
      .then((response) => {
        runInAction(() => {
          this.subscriptions = response.data;

          response.data.sort((a, b) => b.id - a.id);

          this.subscription = response.data[0];
        });
      })
      .finally(() => this.hideLoader());
  }

  createSubscription(
    postData: SubscriptionRequest,
    callback: (data: SubscriptionResponse | null) => void,
  ) {
    this.showLoader();

    const licenceApi = new LicenceApi(this.mainStore.apiConfig);
    licenceApi
      .createSubscription(postData)
      .then((response) => {
        runInAction(() => {
          if (!this.subscriptions) {
            this.subscriptions = [];
          }
          this.subscriptions.push(response.data);

          this.subscription = response.data;

          callback(response.data);
        });
      })
      .catch(() => callback(null))
      .finally(() => this.hideLoader());
  }

  getSubscriptionInfo() {
    this.showLoader();

    const licenceApi = new LicenceApi(this.mainStore.apiConfig);
    licenceApi
      .getSubscriptionInfo()
      .then((response) => {
        runInAction(() => {
          this.licencePaymentInfos = response.data;
          this.selectPaymentInfos(response.data);
        });
      })
      .finally(() => this.hideLoader());
  }

  cancelStripeSubscription(id: string, callback: () => void) {
    this.showLoader();

    const stripeApi = new StripeApi(this.mainStore.apiConfig);
    stripeApi
      .cancelSubscription(id)
      .then(() => {
        runInAction(() => {
          callback();
        });
      })
      .finally(() => this.hideLoader());
  }

  selectPaymentInfos(infos: LicencePaymentInfoResponse[]) {
    this.selectedPaymentInfos = infos;
  }

  getReferralsInfo() {
    this.showLoader();

    const referralApi = new ReferralApi(this.mainStore.apiConfig);
    referralApi
      .getReferralsInfo()
      .then((response) => {
        runInAction(() => {
          this.referralInfo = response.data;
        });
      })
      .finally(() => this.hideLoader());
  }

  getCouponInfo(code: string) {
    if (code !== '') {
      this.showLoader();

      const licenceApi = new LicenceApi(this.mainStore.apiConfig);
      licenceApi
        .getCouponInfo(code)
        .then((response) => {
          runInAction(() => {
            this.couponInfo = response.data;
          });
        })
        .finally(() => this.hideLoader());
    } else {
      this.couponInfo = null;
    }
  }

  createReferralsLicences(callback: (success: boolean) => void) {
    this.showLoader();

    const licenceApi = new LicenceApi(this.mainStore.apiConfig);
    licenceApi
      .createLicencesForReferrals()
      .then((response) => {
        runInAction(() => {
          this.getLicences();
          this.getReferralsInfo();
          this.getSubscriptionInfo();
          callback(response.data.success);
        });
      })
      .catch(() => callback(false))
      .finally(() => this.hideLoader());
  }

  getCountries() {
    this.showLoader();

    const countryApi = new CountryApi(this.mainStore.apiConfig);
    countryApi
      .listCountries()
      .then((response) => {
        runInAction(() => {
          this.countries = response.data;
        });
      })
      .finally(() => this.hideLoader());
  }

  clear() {
    this.facilities = null;
    this.licences = null;
    this.licencePaymentInfos = null;
    this.referralInfo = null;
    this.subscriptions = null;
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }

  setStep(step: number) {
    this.activeStep = step;
  }

  nextStep() {
    this.stepperRef.current?.scrollIntoView();
    this.activeStep++;
  }

  previousStep() {
    this.stepperRef.current?.scrollIntoView();
    this.activeStep--;
  }

  setPaymentMethod(method: SubscriptionRequestPaymentMethodEnum) {
    this.paymentMethod = method;
  }

  setPaymentError(error: StripeError) {
    this.paymentError = error;
  }

  // Computeds

  get totalPrice() {
    let total = 0;
    this.selectedPaymentInfos?.forEach((i) => {
      total += i.price;
    });

    return this.priceFormatter.format(total / 100);
  }

  get subscriptionRange() {
    if (this.selectedPaymentInfos.length > 0) {
      let minPaymentValid = this.selectedPaymentInfos[0];

      this.selectedPaymentInfos.forEach((p) => {
        if (p.validFrom < minPaymentValid.validFrom) {
          minPaymentValid = p;
        }
      });

      return {
        start: minPaymentValid.validFrom,
        end: minPaymentValid.validTo,
      };
    }
    return {
      start: null,
      end: null,
    };
  }

  get invalidCupon() {
    if (this.couponInfo === null) {
      return false;
    }
    return !this.couponInfo.valid;
  }

  get validCupon() {
    if (this.couponInfo === null) {
      return false;
    }
    return this.couponInfo.valid;
  }

  get couponDiscount() {
    if (this.couponInfo?.amountOff) {
      return this.priceFormatter.format(this.couponInfo?.amountOff / 100);
    } else {
      return `${this.couponInfo?.percentOff}%`;
    }
  }
}

export default BillingStore;
