import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  useBillingStore,
  useMainStore,
} from '../../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import NoWrap from '../../../../components/general/NoWrap';

import DateFormat from '../../../../utils/DateFormat';
import { LicenceResponse } from '../../../../serverApi/snapguest';

const FacilitiesForm: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const billingStore = useBillingStore();

  const handleConfirm = () => {
    billingStore.nextStep();
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('licencePaymentInfo.facility')}</TableCell>
                <TableCell>{t('licencePaymentInfo.price')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingStore.licencePaymentInfos?.map((info) => {
                const price = billingStore.priceFormatter.format(
                  info.price / 100,
                );

                const selected =
                  billingStore.selectedPaymentInfos.find(
                    (p) => p.facilityId === info.facilityId,
                  ) !== undefined;

                const facilityLicences = billingStore.licences?.filter(
                  (l) => l.facilityId === info.facilityId,
                );

                let maxLicence: LicenceResponse | null = null;
                if (facilityLicences) {
                  maxLicence = facilityLicences[0];
                  facilityLicences.forEach((l) => {
                    if (maxLicence !== null && l.validTo > maxLicence.validTo) {
                      maxLicence = l;
                    }
                  });
                }

                return (
                  <TableRow key={info.facilityId}>
                    <TableCell>
                      <Typography variant="body1" gutterBottom>
                        <b>{info.facilityName}</b>
                      </Typography>
                      <Typography variant="body2" display="block">
                        {t('licencePaymentInfo.beds')}
                        {': '}
                        <b>{info.beds}</b>
                      </Typography>
                      <Typography variant="body2" display="block">
                        {t('home.licenceFor')}
                        {': '}
                        <NoWrap>
                          <b>
                            {info.maxBeds === -1
                              ? '50+'
                              : info.minBeds + ' - ' + info.maxBeds}{' '}
                            {t('home.beds')}
                          </b>
                        </NoWrap>
                      </Typography>
                      <Typography variant="body2" display="block">
                        {t('home.currentValidTo')}{' '}
                        <NoWrap>
                          <b>
                            {maxLicence ? (
                              <DateFormat date={maxLicence.validTo} />
                            ) : (
                              '-'
                            )}
                          </b>
                        </NoWrap>
                      </Typography>
                      {selected && billingStore.subscriptionRange.end && (
                        <Typography variant="body2">
                          {t('home.extendValidTo')}{' '}
                          <NoWrap>
                            <b>
                              <DateFormat
                                date={billingStore.subscriptionRange.end}
                              />
                            </b>
                          </NoWrap>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{price}</Typography>
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selected}
                        onChange={() => {
                          if (
                            billingStore.selectedPaymentInfos.find(
                              (p) => p.facilityId === info.facilityId,
                            ) === undefined
                          ) {
                            billingStore.selectPaymentInfos([
                              ...billingStore.selectedPaymentInfos,
                              info,
                            ]);
                          } else {
                            const newPaymentInfos =
                              billingStore.selectedPaymentInfos.filter(
                                (p) => p.facilityId !== info.facilityId,
                              );
                            billingStore.selectPaymentInfos(newPaymentInfos);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell align="right">
                  <Typography variant="body1">
                    <b>
                      {t('home.total')}
                      {':'}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="body1">
                    <b>{billingStore.totalPrice}</b>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Typography variant="body1">
                    <b>
                      {t('home.validFrom')}
                      {':'}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="body1">
                    <b>
                      <DateFormat date={billingStore.subscriptionRange.start} />
                    </b>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Typography variant="body1">
                    <b>
                      {t('home.validTo')}
                      {':'}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="body1">
                    <b>
                      <DateFormat date={billingStore.subscriptionRange.end} />
                    </b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {billingStore.referralInfo && billingStore.referralInfo.bonusDays > 0 && (
        <Grid size={12}>
          <Alert severity="warning">{t('home.referralWarning')}</Alert>
        </Grid>
      )}
      {mainStore.customer &&
        mainStore.customer.taxSubject &&
        mainStore.isCroatia && (
          <Grid size={12}>
            <Alert severity="info">{t('home.taxInfo')}</Alert>
          </Grid>
        )}
      {mainStore.isSlovenia && (
        <Grid size={12}>
          <Alert severity="info">{t('home.taxIncluded')}</Alert>
        </Grid>
      )}
      <Grid size={12}>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid size="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                billingStore.previousStep();
              }}
            >
              {t('general.back')}
            </Button>
          </Grid>
          <Grid size="auto">
            <Button
              variant="contained"
              color="primary"
              disabled={billingStore.selectedPaymentInfos.length === 0}
              onClick={handleConfirm}
            >
              {t('general.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(FacilitiesForm);
