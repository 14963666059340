import { addHours } from 'date-fns';
import i18n from 'i18next';
import { setDefaultOptions } from 'date-fns';
import { hr, enGB, sl } from 'date-fns/locale';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
  when,
} from 'mobx';
import StorageService from '../services/StorageService';
import { SubDomain } from '../enums/SubDomain';
import {
  Configuration,
  UserAuthApi,
  CustomerApi,
  LoginRequest,
  RegisterRequest,
  PasswordResetRequest,
  PasswordChangeRequest,
  UserApi,
  CustomerRequest,
  UserResponse,
  CustomerResponse,
} from '../serverApi/snapguest';

class MainStore {
  // Observable
  loader = false;
  loaded = false;

  user?: UserResponse;
  customer?: CustomerResponse;

  server: string = StorageService.getServer();
  subDomain: SubDomain = SubDomain.Billing;

  authToken: string | null = StorageService.getToken();
  authTokenExpire: Date = StorageService.getTokenExpire();

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);

    // Token
    const token = urlParams.get('token');

    if (token !== null && !window.location.pathname.includes('app')) {
      this.setAuthentication(token);
    }

    // Server
    const server = urlParams.get('server');

    if (server !== null) {
      this.changeServer(server);
    }

    // Sub domain
    const forcedSubDomain = process.env.REACT_APP_FORCED_SUBDOMAIN;
    if (forcedSubDomain) {
      this.setSubDomain(forcedSubDomain);
    } else {
      const host = window.location.host;

      const parts = host.split('.');

      if (parts.length >= 3) {
        const subDomain = parts[0];

        this.setSubDomain(subDomain);
      }
    }

    makeObservable(this, {
      loader: observable,
      loaded: observable,
      user: observable,
      customer: observable,
      server: observable,
      subDomain: observable,
      authToken: observable,
      authTokenExpire: observable,

      changeServer: action,
      setSubDomain: action,
      showLoader: action,
      hideLoader: action,
      getUserCustomer: action,
      updateCustomer: action,

      isAuthenticated: computed,
      baseUrl: computed,
      isSlovenia: computed,
      isCroatia: computed,
      apiConfig: computed,
    });

    when(
      // Once...
      () => this.isAuthenticated,
      // ... then.
      () => {
        this.getUserCustomer();
      },
    );
  }

  // Actions

  getUserCustomer() {
    if (this.authToken !== null) {
      this.showLoader();

      const userAuthApi = new UserApi(this.apiConfig);
      const customerApi = new CustomerApi(this.apiConfig);

      Promise.all([
        userAuthApi
          .me()
          .then((response) => {
            runInAction(() => {
              if (response.data) {
                this.user = response.data;
              } else {
                this.logout();
              }
              this.hideLoader();
            });
          })
          .catch(() => {
            this.logout();
          }),
        customerApi.getAuthCustomer().then((response) => {
          runInAction(() => {
            this.customer = response.data;
          });
        }),
      ]).then(() => {
        runInAction(() => {
          this.loaded = true;
          this.hideLoader();
        });
      });
    }
  }

  updateCustomer(postData: CustomerRequest) {
    if (this.authToken !== null) {
      this.showLoader();

      const customerApi = new CustomerApi(this.apiConfig);

      customerApi
        .updateAuthCustomer(postData)
        .then((response) => {
          runInAction(() => {
            this.customer = response.data;
          });
        })
        .finally(() => this.hideLoader());
    }
  }

  login(postData: LoginRequest, callback: (success: boolean) => void) {
    this.showLoader();

    const userAuthApi = new UserAuthApi(this.apiConfig);
    userAuthApi
      .login(postData)
      .then((response) => {
        runInAction(() => {
          this.setAuthentication(response.data.token);
          callback(true);
        });
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => this.hideLoader());
  }

  setAuthentication(token: string) {
    const expire = addHours(new Date(), 1);
    StorageService.setToken(token, expire);
    this.authToken = token;
    this.authTokenExpire = expire;
  }

  logout() {
    this.authToken = null;
    StorageService.removeToken();
    StorageService.removeServer();
  }

  register(
    postData: RegisterRequest,
    callback: (success: boolean) => void,
    refUser?: number,
    refCustomer?: number,
  ) {
    this.showLoader();

    const userAuthApi = new UserAuthApi(this.apiConfig);
    userAuthApi
      .register(postData, refUser, refCustomer)
      .then((response) => {
        callback(response.data.success);
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => this.hideLoader());
  }

  resetPassword(
    postData: PasswordResetRequest,
    callback: (success: boolean) => void,
  ) {
    this.showLoader();

    const userAuthApi = new UserAuthApi(this.apiConfig);
    userAuthApi
      .passwordReset(postData)
      .then((response) => {
        callback(response.data.success);
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => this.hideLoader());
  }

  changePassword(
    token: string,
    postData: PasswordChangeRequest,
    callback: (success: boolean) => void,
  ) {
    this.showLoader();

    const userAuthApi = new UserAuthApi(this.apiConfig);
    userAuthApi
      .passwordChange(token, postData)
      .then((response) => {
        callback(response.data.success);
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => this.hideLoader());
  }

  mailConfirm(token: string, callback: (success: boolean) => void) {
    this.showLoader();

    const userAuthApi = new UserAuthApi(this.apiConfig);
    userAuthApi
      .mailTokenValid(token)
      .then((response) => {
        callback(response.data.success);
      })
      .catch(() => {
        callback(false);
      })
      .finally(() => this.hideLoader());
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }

  changeServer(server: string) {
    this.server = server;
    StorageService.setServer(server);

    // Change locale
    let locale = enGB;

    switch (server) {
      case 'hr':
        locale = hr;
        i18n.changeLanguage('hr');
        break;
      case 'si':
        locale = sl;
        i18n.changeLanguage('sl');
        break;
    }

    setDefaultOptions({
      locale,
    });
  }

  setSubDomain(subDomain: string) {
    switch (subDomain) {
      case 'admin':
      case 'admin-dev':
        this.subDomain = SubDomain.Admin;
        break;
      case 'checkin':
      case 'checkin-dev':
        this.subDomain = SubDomain.Checkin;
        break;
      case 'billing':
      case 'billing-dev':
      default:
        this.subDomain = SubDomain.Billing;
    }
  }

  // Computeds
  get isAuthenticated(): boolean {
    return this.authToken !== null && this.authTokenExpire > new Date();
  }

  get baseUrl(): string {
    switch (this.server) {
      case 'hr':
        return process.env.REACT_APP_HR_BACKEND_URL ?? '';
      case 'si':
      default:
        return process.env.REACT_APP_SI_BACKEND_URL ?? '';
    }
  }

  get isSlovenia(): boolean {
    return this.server === 'si';
  }

  get isCroatia(): boolean {
    return this.server === 'hr';
  }

  get languageCountry(): string {
    switch (i18n.language) {
      case 'hr':
        return 'hr-HR';
      case 'sl':
        return 'sl-SI';
      case 'de':
        return 'de_DE';
      case 'it':
        return 'it_IT';
      case 'fr':
        return 'fr_FR';
      case 'es':
        return 'es_ES';
      default:
        return 'en-SI';
    }
  }

  get apiConfig(): Configuration {
    const config = new Configuration();

    config.basePath = this.baseUrl;
    config.baseOptions = {
      headers: {
        Authorization: 'Bearer ' + this.authToken,
        'Accept-Language': this.languageCountry,
      },
    };

    return config;
  }
}

export default MainStore;
