import { makeObservable, observable, action, runInAction, when } from 'mobx';
import MainStore from './MainStore';
import {
  CheckInPartRequest,
  CountryApi,
  CountryResponse,
  EtCpDocumentTypeResponse,
  ETurizemApi,
  EvCpArrivalOrganisationResponse,
  EvCpDocumentTypeResponse,
  EVisitorApi,
  PublicReservationPartResponse,
  ReservationApi,
  TouristApi,
} from '../serverApi/snapguest';

class CheckinStore {
  mainStore: MainStore;

  // Observable
  loader = false;

  validLink = true;

  partId?: number;
  customerId?: number;
  note?: string;

  reservationPart?: PublicReservationPartResponse;

  countries: CountryResponse[] = [];
  documentTypes: EvCpDocumentTypeResponse[] | EtCpDocumentTypeResponse[] = [];

  arrivalOrganizations: EvCpArrivalOrganisationResponse[] = [];
  settlements: string[] = [];

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;

    const urlParams = new URLSearchParams(window.location.search);

    const partId = urlParams.get('partId');

    if (partId !== null) {
      this.partId = parseInt(partId);
    }

    const customerId = urlParams.get('customerId');

    if (customerId !== null) {
      this.customerId = parseInt(customerId);
    }

    const note = urlParams.get('note');

    if (note !== null) {
      this.note = note;
    }

    if (!partId || !customerId) {
      this.validLink = false;
    }

    makeObservable(this, {
      loader: observable,
      validLink: observable,
      partId: observable,
      customerId: observable,
      note: observable,
      reservationPart: observable,
      countries: observable,
      documentTypes: observable,

      getReservation: action,
      getCountries: action,
      getDocumentTypes: action,
      getArrivalOrganizations: action,
      getSettlements: action,
      selfCheckInTourist: action,
      showLoader: action,
      hideLoader: action,
    });

    when(
      // Once...
      () => this.validLink,
      // ... then.
      () => {
        this.getReservation();
        this.getCountries();
        this.getDocumentTypes();
        this.getArrivalOrganizations();
        this.getSettlements();
      },
    );
  }

  // Actions

  getReservation() {
    if (this.partId !== undefined && this.customerId !== undefined) {
      this.showLoader();

      const reservationApi = new ReservationApi(this.mainStore.apiConfig);
      reservationApi
        .findPublicPart(this.partId, this.customerId)
        .then((response) => {
          runInAction(() => {
            this.reservationPart = response.data;
          });
        })
        .catch(() => {
          runInAction(() => {
            this.validLink = false;
          });
        })
        .finally(() => this.hideLoader());
    }
  }

  getCountries() {
    this.showLoader();

    const countryApi = new CountryApi(this.mainStore.apiConfig);
    countryApi
      .listCountries()
      .then((response) => {
        runInAction(() => {
          this.countries = response.data;
        });
      })
      .finally(() => this.hideLoader());
  }

  getDocumentTypes() {
    this.showLoader();

    if (this.mainStore.isSlovenia) {
      const eTurizemApi = new ETurizemApi(this.mainStore.apiConfig);
      eTurizemApi
        .listEtDocumentTypes()
        .then((response) => {
          runInAction(() => {
            this.documentTypes = response.data;
          });
        })
        .finally(() => this.hideLoader());
    }

    if (this.mainStore.isCroatia) {
      const eVisitorApi = new EVisitorApi(this.mainStore.apiConfig);
      eVisitorApi
        .listEvDocumentTypes()
        .then((response) => {
          runInAction(() => {
            this.documentTypes = response.data;
          });
        })
        .finally(() => this.hideLoader());
    }
  }

  getArrivalOrganizations() {
    if (this.mainStore.isCroatia) {
      this.showLoader();

      const eVisitorApi = new EVisitorApi(this.mainStore.apiConfig);
      eVisitorApi
        .listEvArrivalOrganisations()
        .then((response) => {
          runInAction(() => {
            this.arrivalOrganizations = response.data;
          });
        })
        .finally(() => this.hideLoader());
    }
  }

  getSettlements() {
    if (this.mainStore.isCroatia) {
      this.showLoader();

      const eVisitorApi = new EVisitorApi(this.mainStore.apiConfig);
      eVisitorApi
        .listEvSettlements()
        .then((response) => {
          runInAction(() => {
            this.settlements = response.data.map(
              (settlement) => settlement.name,
            );
          });
        })
        .finally(() => this.hideLoader());
    }
  }

  selfCheckInTourist(
    customerId: number,
    postData: CheckInPartRequest,
    callback: (success: boolean) => void,
  ) {
    this.showLoader();

    const touristApi = new TouristApi(this.mainStore.apiConfig);
    touristApi
      .selfCheckInTourist(customerId, postData)
      .then((response) => {
        runInAction(() => {
          this.reservationPart!.checkedInTourists += 1;

          callback(response.data.success);
        });
      })
      .catch(() => callback(false))
      .finally(() => this.hideLoader());
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }
}

export default CheckinStore;
